
import React, { useContext } from "react";
import QRCode from "react-qr-code";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';
import Footer from '../components/Footer';
import Prompt from '../components/Prompt';
import { inIframe, isMobile } from "../util/Helpers";
import appleWallet from '../util/img/apple_wallet.png';
import qrCode from '../util/img/qr-code.png';

function Card() {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);

    const userAgent = navigator.userAgent.toLowerCase();

    const isIOS = /iphone|ipad|ipod/g.test(userAgent);

    return (
        <React.Fragment>
            <div className="cp-content">
                <div className="cp-card">
                    {(context.isEditable || context.settings?.content?.wording?.card?.instructions !== "") &&
                        <div className="cp-card-header cp-card-header-border">
                            <p>
                                <Editable
                                    id="marsello-card-instrucitons"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.card?.instructions}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, card: { ...s.card, instructions: c } } }}
                                />
                            </p>
                        </div>
                    }
                    <div className="cp-card-content text-center">
                        <div className="cp-qr-code">
                            {context.customer ? <QRCode value={context.customer.email} size={250} /> : <img src={qrCode} height="244" width="244" />}
                        </div>
                        {isIOS && context.customer && context.customer.id &&
                            <a className="mt-2" href={`https://wallet.marsello.com/generate/ApplePass/${context.customer.id}`}>
                                <img height="46" src={appleWallet} width="148" />
                            </a>
                        }
                        {isMobile() && !inIframe() && <button onClick={() => { !context.isEditable && navigation.setPage("SaveToHomeScreen") }}>Save to home screen</button>}
                    </div>
                    {context.customer ?
                        <div className="cp-card-footer cp-card-footer-border">
                            <h2>{context.customer.phone}</h2>
                            <p><strong>{context.customer.firstName} {context.customer.lastName} &middot;</strong> {context.customer.email}</p>
                        </div>
                        :
                        <div className="cp-card-footer cp-card-footer-border">
                            <div className="cp-placeholder-heading"></div>
                            <div className="cp-placeholder-text"></div>
                        </div>
                    }
                </div>
                <Footer />
                {!context.customer &&
                    <Prompt />
                }
            </div>
        </React.Fragment>
    )
}

export default Card;
