import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ChevronRight } from "react-feather";
import { CouponApi, CustomerPortalWording, RewardModel, TierRewardModel } from "../../../../apiclient";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';
import { inIframe } from "../util/Helpers";
import Icon from '../util/Icon';
interface RedeemItemParams {
    reward: RewardModel | TierRewardModel;
    tierName?: string
}

const RedeemItem = ({ reward, tierName }: RedeemItemParams) => {

    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    const [loading, setLoading] = useState(false);
    const [isInstore, setIsInstore] = useState(true);

    const [redeemMergeTags, setRedeemMergeTags] = useState({ pointsToClaim: ((reward.points ?? 0) - (context.customer?.balance ?? 0)), rewardPoints: reward.points })
    const [rewardTierName, setRewardTierName] = useState<string | undefined>(context.account?.tiers?.find(f => f.linkedTierRewards?.find(r => r.id === reward.id))?.name);
    const [inWrongTier, setInWrongTier] = useState((tierName && !context.customer?.availableTiers?.includes(tierName)) || (rewardTierName && !context.customer?.availableTiers?.includes(rewardTierName)));

    let couponApi = new CouponApi(apiConfig.configuration, apiConfig.baseUrl + "/api");

    useEffect(() => {
        setIsInstore(!inIframe());
        setRedeemMergeTags({ pointsToClaim: ((reward.points ?? 0) - (context.customer?.balance ?? 0)), rewardPoints: reward.points });
        let rewardTier = context.account?.tiers?.find(f => f.linkedTierRewards?.find(r => r.id === reward.id))?.name
        setRewardTierName(rewardTier);
        setInWrongTier((tierName && !context.customer?.availableTiers?.includes(tierName)) || (rewardTier && !context.customer?.availableTiers?.includes(rewardTier)));
    }, [context.account?.tiers, context.customer?.availableTiers, context.customer?.balance, reward, tierName])

    const renderIcon = () => {
        let result = <React.Fragment></React.Fragment>;
        if (context.customer) {
            if (inWrongTier) {
                result = (<Icon icon="Lock" />);
            }
            else if (((reward.points ?? 0) - (context.customer?.balance ?? 0)) > 0) {
                let angle = 360 * ((context.customer?.balance ?? 0) / (reward.points ?? 1));
                const style = {
                    background: 'conic-gradient(transparent 0deg ' + angle + 'deg, rgba(var(--cp-color-border), 1) ' + angle + 'deg 360deg), conic-gradient(rgba(var(--cp-color-progressGradientStart), 1) 0deg, rgba(var(--cp-color-progressGradientEnd), 1))'
                }
                result = (
                    <div className="cp-progress-circle" style={style}></div>
                );
            } else {
                if (loading) {
                    result = (<div className="cp-btn">
                        <Spinner animation="border" role="status" size="sm"><span className="visually-hidden">Loading...</span></Spinner>
                    </div >);
                }
                else {
                    result = (
                        <div className="cp-btn" onClick={handleRewardClick}>
                            {(context.isEditable || context.settings?.content?.wording?.redeem?.claimButton !== "") &&
                                <Editable
                                    id="marsello-redeem-claim-button"
                                    display="inline"
                                    initialContent={context.settings?.content?.wording?.redeem?.claimButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...redeemMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, claimButton: c } } }}
                                />
                            }
                        </div>
                    );
                }
            }
        } else {
            result = (
                <ChevronRight className="cp-icon cp-icon-chevron" height="18" width="18" />
            );
        }
        return result;
    }

    const handleRewardClick = () => {
        if (isInstore && reward.discountType !== "custom-reward") {
            context.updateSelectedReward(reward);
            !context.isEditable && context.customer && (context.customer?.balance ?? 0) - (reward.points ?? 0) >= 0 && navigation.setPage("ClaimInstore");
        }
        else if (reward.points === 0) {
            // should be a coupon so no need to redeem
            context.updateSelectedReward(reward);
            !context.isEditable && context.customer && navigation.setPage("ClaimOnline");
        }
        else {
            if (!context.isEditable && context.customer) {
                setLoading(true);
                // claim reward
                couponApi.apiCouponCustomerCustomerIdRewardRewardIdPost(context.customer?.id ?? "", reward.id ?? "")
                    .then((res) => {
                        context.updateSelectedReward(res.data);
                        context.refreshCustomer();
                        setLoading(false);
                        navigation.setPage("ClaimOnline");
                    })
                    .catch(() => { setLoading(false) });
            }
        }
    }

    return (
        <React.Fragment>
            <li className={`cp-item cp-item-redeem ${((context.customer?.balance ?? 0) - (reward.points ?? 0) < 0 || !context.customer || inWrongTier) && 'disabled'}
                        ${context.customer && (reward.points ?? 0) - (context.customer?.balance ?? 0) > 0 && 'in-progress'}`}
            >
                {context.settings?.content?.icon?.useRedeemIcon &&
                    <figure className="cp-icon-container">
                        <Icon icon={reward.discountType} />
                    </figure>
                }
                <div className="cp-item-content">
                    <h6>{reward.name}</h6>
                    {tierName && <label htmlFor="" className="cp-vip-tag">{" " + tierName + " "}</label>}
                    {(!tierName && rewardTierName) && <label htmlFor="" className="cp-vip-tag">{" " + rewardTierName + " "}</label>}
                    <small>
                        {(reward.points ?? 0)} {context.mergeTags?.default?.pointsLabel}
                        {context.customer && ((reward.points ?? 0) - (context.customer?.balance ?? 0)) > 0 &&
                            <React.Fragment> &middot;
                                {(context.isEditable || context.settings?.content?.wording?.redeem?.pointsToClaim !== "") &&
                                    <Editable
                                        id="marsello-redeem-points"
                                        display="inline"
                                        initialContent={context.settings?.content?.wording?.redeem?.pointsToClaim}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...redeemMergeTags }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pointsToClaim: c } } }}
                                    />
                                }
                            </React.Fragment>
                        }
                    </small>
                </div>
                {renderIcon()}
            </li>
        </React.Fragment >
    );
};

export default RedeemItem;
