import React, { useContext } from "react";
import { BrandingContext } from "../../../../contexts/BrandingContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Launcher from '../components/Launcher';
import Panel from '../panelTemplates/Default';

const MobilePanel = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(BrandingContext);
    const customerContext = useContext(CustomerContext);
    let settings = customerContext?.settings ?? context.settings;

    return (
        <div className={`cp-portal cp-portal-uncoupled cp-panel-mobile cp-launcher-${settings?.configuration?.launcher?.design} cp-launcher-${settings?.configuration?.launcher?.position}`}>
            {navigation.page !== "Launcher" &&
                <React.Fragment>
                    <Panel />
                </React.Fragment>
            }
            {settings?.configuration?.launcher?.visibility !== "HideOnAll" &&
                <React.Fragment>
                    <Launcher />
                </React.Fragment>
            }
        </div>
    );
};

export default MobilePanel;
