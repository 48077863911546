import React, { useContext, useEffect, useState } from "react";
import { BrandingContext } from "../../../contexts/BrandingContext";
import { NavigationContext } from "../../../contexts/NavigationContext";
import Center from "./portalTemplates/Center";
import MobilePanel from "./portalTemplates/Mobile";
import Side from "./portalTemplates/Side";
import "./util/portal.scss";


const Widget = () => {
    const context = useContext(BrandingContext);
    const navigation = useContext(NavigationContext);
    let settings = context.settings;
    var changes = context?.settings?.computedStyles ? JSON.parse(context?.settings?.computedStyles) : null;
    if (changes != null && Object.keys(changes).length > 0) {
        const root = document.querySelector(':root') as HTMLElement;
        const setChanges = (vars: any[]) => Object.entries(vars).forEach(v => root?.style?.setProperty(v[0], v[1]));
        setChanges(changes);
    }
    //Either in here or a context we can grab anything from the host page to detect which integration this is.
    //This will also help with the is mobile bool below
    const [widgetUrl, setWidgetUrl] = useState("");

    useEffect(() => {
        var url = window?.document?.URL?.toLowerCase();
        let path = (url?.includes("?rewards") ? "/redeem" : "")
        const urlParams = new URLSearchParams(window.location.search);
        const couponId = urlParams.get('couponId');
        if (couponId) {
            //Set the couponId as query string so that customerContext can navigation to claimOnline
            path += "?couponId=" + couponId;
        }

        //@ts-ignore
        let baseUrl = window?.marselloSettings?.baseUrl ?? window.location.origin;
        //@ts-ignore
        const token = window?.marselloSettings?.token;
        if (token) {
            var widgetUrl = new URL(baseUrl + path);
            widgetUrl.searchParams.append("token", token);
            baseUrl = widgetUrl;
        }
        setWidgetUrl(baseUrl);

        if (navigation.page !== "Launcher" && !url.endsWith("?loyal") && !url.includes("?rewards")) {
            navigation.setPage("Launcher");
        }
    }, [])

    let result = <React.Fragment></React.Fragment>;
    if (settings?.isMobile) {
        result = (
            <MobilePanel />
        );
    } else {
        switch (settings?.configuration?.panel?.template) {
            case "CenterPanel":
                result = (
                    <Center url={widgetUrl} />
                );
                break;
            case "SidePanel":
                result = (
                    <Side url={widgetUrl} />
                );
                break;
            default:
                result = (
                    <Side url={widgetUrl} />
                );
                break;
        }
    }

    return result;
};

export default Widget;
