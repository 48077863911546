import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import { AccountApi, CustomerApi } from "../apiclient/api";
import { CustomerMergeTags, CustomerModelWithAvailableTiers, CustomerPortalMergeTag, CustomerPortalSettings, CustomerPortalWording, CustomerUpdateModel, RewardModel, SignInPageViewModel, UtmTrackingPostRequestModel } from "../apiclient/models";
import { apiConfig } from "../config";
import { NavigationContext } from "./NavigationContext";

export interface SignInProps {
    authenticated: boolean,
    account: SignInPageViewModel | null,
    customer: CustomerModelWithAvailableTiers | null,
    isMobile: boolean,
    isEditable: boolean,
    loading: boolean,
    selectedReward: RewardModel,
    updateSelectedReward: (reward: RewardModel) => void
    settings: CustomerPortalSettings | null,
    mergeTags: CustomerPortalMergeTag | null,
    updateCustomerDetails: (update: CustomerUpdateModel) => void,
    refreshCustomer: (onComplete?: () => void) => void,
    setCustomer: (customer: CustomerModelWithAvailableTiers | null) => void,
    setSettings: (settings: CustomerPortalSettings, isEditable: boolean, isMobile: boolean) => void,
    setWording: (setterFunc: (wording: CustomerPortalWording, c: string) => CustomerPortalWording, value: string) => void,
    signOut: () => void,
    editingWidget: boolean,
    showQueryCoupon: boolean,
    setShowQueryCoupon: (val: boolean) => void
}
const initialState: SignInProps = {
    authenticated: false,
    account: null,
    customer: null,
    settings: null,
    isEditable: false,
    isMobile: false,
    mergeTags: null,
    refreshCustomer: () => {

    },
    setCustomer: (customer: CustomerModelWithAvailableTiers | null) => { },
    setSettings: (settings: CustomerPortalSettings, isEditable: boolean, isMobile: boolean) => {

    },
    setWording: (setterFunc: (wording: CustomerPortalWording, c: string) => CustomerPortalWording, value: string) => { },

    signOut: () => {

    },
    updateCustomerDetails: (update: CustomerUpdateModel) => { },
    loading: true,
    selectedReward: {} as RewardModel,
    updateSelectedReward: (reward: RewardModel) => { },
    editingWidget: false,
    showQueryCoupon: true,
    setShowQueryCoupon: (val: boolean) => { },
}

const CustomerContext = createContext(initialState);

function CustomerProvider({ children }: any) {
    //@ts-ignore
    const baseUrl = window?.marselloSettings?.baseUrl ?? apiConfig.baseUrl;

    const [queryParams] = useSearchParams();
    var currentUrl = new URL(window.location.href);
    let token = queryParams.get("token") ?? localStorage.getItem("marsello-token");
    if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorage.setItem("marsello-token", token);
        // remove the token from the url so it doesnt look so ugly
        currentUrl.searchParams.delete("token");
        window.history.replaceState({}, '', currentUrl);
    }
    let otc = queryParams.get("otc") ?? undefined;
    if (otc) {
        currentUrl.searchParams.delete("otc");
        window.history.replaceState({}, '', currentUrl);
    }


    let customerApi = new CustomerApi(
        apiConfig.configuration,
        baseUrl
    );
    let accountApi = new AccountApi(
        apiConfig.configuration,
        baseUrl
    );
    const navigation = useContext(NavigationContext);

    const refreshCustomer = (onComplete?: () => void) => {
        customerApi.customerPortalGet()
            .then(response => {
                if (!response.data.email) {
                    setState(state => ({ ...state, authenticated: true, customer: null, loading: false }))
                    navigation.setPage("Signin");
                } else {
                    let customerMergeTags = {
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        balance: response.data.balance,
                        tier: response.data.tierName
                    } as CustomerMergeTags
                    setState(state => ({ ...state, authenticated: true, customer: response.data, loading: false, mergeTags: { ...state.mergeTags, customer: customerMergeTags } }))
                    let localUtmSource = localStorage.getItem('utm_source');
                    if (localUtmSource) {
                        var postModel = {
                            customerId: response.data.id,
                            utmCampaign: localStorage.getItem('utm_campaign') ?? "",
                            utmId: localStorage.getItem('utm_id') ?? "",
                            utmMedium: localStorage.getItem('utm_medium') ?? "",
                            utmSource: localUtmSource ?? "",
                            emailAddress: response.data.email
                        } as UtmTrackingPostRequestModel;
                        accountApi
                            .accountCustomerUtmTrackingUpdatePost("1", postModel).then(() => {
                                localStorage.removeItem("utm_source");
                                localStorage.removeItem("utm_medium");
                                localStorage.removeItem("utm_campaign");
                                localStorage.removeItem("utm_id");
                            })
                            .catch(function (error) { });
                    }
                    onComplete && onComplete();
                }
            }).catch((error: any) => {
                if (error.response?.status === 401) {
                    setState(state => ({ ...state, authenticated: false, customer: null, loading: false }))
                }
                else if (error.response?.status === 404) {
                    setState(state => ({ ...state, authenticated: true, customer: null, loading: false }))
                    navigation.setPage("Signin");
                }
                onComplete && onComplete();
            })
    }

    const updateSelectedReward = (reward: RewardModel) => {
        setState(state => ({ ...state, selectedReward: reward }));
    }

    const updateCustomerDetails = (update: CustomerUpdateModel) => {
        setState(state => (
            {
                ...state, customer: {
                    ...state.customer,
                    firstName: update.firstName ?? state?.customer?.firstName,
                    lastName: update.lastName ?? state?.customer?.lastName,
                    phone: update.phone ?? state?.customer?.phone,
                    birthday: update.birthday ?? state?.customer?.birthday,
                    email: update.emailAddress ?? state?.customer?.email,
                    gender: update.gender ?? state?.customer?.gender,
                    subscribed: update.subscribed ?? state?.customer?.subscribed,
                    subscribedSms: update.subscribedSms ?? state?.customer?.subscribedSms,
                    subscribedNotifications: update.subscribedNotifications ?? state?.customer?.subscribedNotifications
                }
            }
        ));
    }
    const signOut = () => {
        localStorage.removeItem("marsello-token");
        window.location.href = '/account/logout'
    }

    const setSettings = (settings: CustomerPortalSettings, isEditable: boolean, isMobile: boolean) => {
        setState(currentState => ({ ...currentState, settings: settings, isEditable: isEditable, isMobile: isMobile }));
    }

    const setWording = (setterFunc: (wording: CustomerPortalWording | undefined, c: string) => CustomerPortalWording, value: string) => {
        setState(currentState => {
            let newState = { ...currentState, settings: { ...currentState.settings, content: { ...currentState.settings?.content, wording: setterFunc(currentState.settings?.content?.wording, value) } } };
            window.parent.postMessage(newState.settings.content.wording, "*");
            return newState;
        })
    }

    const setCustomer = (state: CustomerModelWithAvailableTiers | null) => {
        let customerMergeTags = {
            firstName: state?.firstName,
            lastName: state?.lastName,
            balance: state?.balance,
            tier: state?.tierName
        } as CustomerMergeTags
        setState(currentState => ({ ...currentState, customer: state, mergeTags: { ...currentState.mergeTags, customer: customerMergeTags } }));
    }
    const setShowQueryCoupon = (val: boolean) => {
        setState(currentState => ({ ...currentState, showQueryCoupon: val }));
    }

    const [state, setState] = useState({ ...initialState, signOut: signOut, refreshCustomer: refreshCustomer, setCustomer: setCustomer, updateCustomerDetails: updateCustomerDetails, setSettings: setSettings, setWording: setWording, updateSelectedReward: updateSelectedReward, setShowQueryCoupon: setShowQueryCoupon });
    let draft = queryParams.get("draft");
    let edit = queryParams.get("edit");
    let widget = queryParams.get("widget");
    let utm_source = queryParams.get('utm_source');
    let utm_medium = queryParams.get('utm_medium');
    let utm_campaign = queryParams.get('utm_campaign');
    let utm_id = queryParams.get('utm_id');
    let customer_id = queryParams.get('customer_id');

    const saveUtmsToStorage = () => {
        if (utm_source) {
            localStorage.setItem('utm_source', utm_source);
        }

        if (utm_medium) {
            localStorage.setItem('utm_medium', utm_medium);
        }

        if (utm_campaign) {
            localStorage.setItem('utm_campaign', utm_campaign);
        }

        if (utm_id) {
            localStorage.setItem('utm_id', utm_id);
        }
        if (customer_id) {
            localStorage.setItem('customer_id', customer_id);
        }
    }
    useEffect(() => {
        const initialize = () => {
            if (!state.account) {
                setState(state => ({ ...state, loading: true }));
                accountApi.accountSignInGet(draft == "true", otc)
                    .then(account => {
                        setState(state => ({ ...state, account: account.data, settings: account.data.settings ?? null, isEditable: edit == "true", mergeTags: account.data.accountMergeTags ?? null, editingWidget: widget == "true" }));
                        saveUtmsToStorage();
                        if (account.data.authToken) {
                            axios.defaults.headers.common["Authorization"] = "Bearer " + account.data.authToken;
                            localStorage.setItem("marsello-token", account.data.authToken);
                        }
                        refreshCustomer();
                    }).catch((error: any) => {
                        setState(state => ({ ...state, authenticated: false, loading: false }));
                    })
            }
        }
        initialize();
    }, []);


    return (
        <CustomerContext.Provider className="h-100" value={{ ...state }}>
            {state.loading ?
                <div className="cp-spinner-container h-100 d-flex gap-2 flex-column justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" role="status" />
                    <p>Loading profile...</p>
                </div> :
                <>
                    <Helmet title={state.account?.accountBranding?.appShortName ?? ""} />

                    {children}</>
            }
        </CustomerContext.Provider>
    );
}

export { CustomerContext, CustomerProvider };

