import React, { useContext } from "react";
import { TierWithRewardsModel } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TierItem from '../components/TierItem';

const TierList = () => {

    const context = useContext(CustomerContext);

    const renderItem = (tier: TierWithRewardsModel, index: number) => {
        return (
            <TierItem
                order={index}
                tier={tier}
            />
        )
    }

    return (
        <React.Fragment>
            <ul className="cp-basic-list">
                {context?.account?.tiers == null || context?.account?.tiers.length == 0 &&
                    <React.Fragment>
                        {renderItem({
                            name: context?.mergeTags?.memberTierName ?? "Member",
                            thresholdValue: 0,
                            pointsPerDollar: context?.account?.earnActivities?.find(a => a.id === "order")?.points,
                            moneyAmountMultiplier: context?.account?.earnActivities?.find(a => a.id === "order")?.threshold
                        }, -1)}
                        {context?.account?.tiers.map((tier, i) => {
                            return renderItem(tier, i);
                        })}
                    </React.Fragment>
                }
            </ul>
        </React.Fragment>
    );
};

export default TierList;
