import React, { useContext } from "react";
import { EarnActivityModel } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import CompletableEarnItem from "./earn/CompletableEarnItem";
import { default as CompleteableEarnItemWithRedirect } from './earn/CompletableEarnItemWithRedirect';
import RepeatableEarnItem from "./earn/RepeatableEarnItem";
interface EarnListParams {
    length: any;
    tabSelected: string;
}


const EarnList = ({ length, tabSelected }: EarnListParams) => {
    const customerContext = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    const renderCSSSheet = () => {
        const rule = `#cp-earn-list li:nth-of-type(1n+${length + 1}) {display: none;}`;
        return (
            <style type="text/css">{rule}</style>
        )
    }

    const earnActivities = customerContext?.customer?.earnActivities ?? customerContext?.account?.earnActivities ?? [];
    const filteredCustomActivities = earnActivities ? earnActivities.filter(activity => (activity.type === 34 || activity.type === 35)) : [];
    return (
        <React.Fragment>
            {length &&
                <> {renderCSSSheet()} </>
            }
            <ul className={`cp-basic-list ${!customerContext?.customer && "cp-basic-list-disabled"}`} id="cp-earn-list">
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "order" && a.available)
                    : earnActivities.some((a: EarnActivityModel) => a.id === "order")) && tabSelected !== "done" &&
                    <RepeatableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "order") ?? {}}
                        earnType="Purchase"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "join" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "join" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "join"))) &&
                    <CompletableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "join") ?? {}}
                        earnType="AccountCreation"
                        onClick={() => !customerContext.isEditable && !customerContext?.customer && navigation.setPage("Signup")}
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "profile-completed" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "profile-completed" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "profile-completed"))) &&
                    <CompletableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "profile-completed") ?? {}}
                        earnType="ProfileCompletion"
                        onClick={() => { !customerContext.isEditable && earnActivities.find((a: EarnActivityModel) => a.id === "profile-completed")?.available && customerContext?.customer && navigation.setPage("Profile") }}
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "birthday" && a.available)
                    : earnActivities.some((a: EarnActivityModel) => a.id === "birthday")) && tabSelected !== "done" &&
                    <RepeatableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "birthday") ?? {}}
                        earnType="BirthdayCelebration"
                        onClick={() => { !customerContext.isEditable && customerContext?.customer && customerContext?.customer?.birthday == null && navigation.setPage("Profile") }}
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "referral" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "referral" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "referral"))) &&
                    <CompletableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "referral") ?? {}}
                        earnType="FriendReferral"
                        onClick={() => !customerContext.isEditable && navigation.setPage("Referral")}
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "product-review" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "product-review" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "product-review"))) &&
                    <CompletableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "product-review") ?? {}}
                        earnType="ProductReview"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "sustainable-action" && a.available)
                    : earnActivities.some((a: EarnActivityModel) => a.id === "sustainable-action")) && tabSelected !== "done" &&
                    <RepeatableEarnItem
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "sustainable-action") ?? {}}
                        earnType="SustainableAction"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "facebook-like" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "facebook-like" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "facebook-like"))) &&
                    <CompleteableEarnItemWithRedirect
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "facebook-like") ?? {}}
                        earnType="FacebookLike"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "twitter-follow" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "twitter-follow" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "twitter-follow"))) &&
                    <CompleteableEarnItemWithRedirect
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "twitter-follow") ?? {}}
                        earnType="TwitterFollow"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "instagram-follow" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "instagram-follow" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "instagram-follow"))) &&
                    <CompleteableEarnItemWithRedirect
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "instagram-follow") ?? {}}
                        earnType="InstagramFollow"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "facebook-share" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "facebook-share" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "facebook-share"))) &&
                    <CompleteableEarnItemWithRedirect
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "facebook-share") ?? {}}
                        earnType="FacebookShare"
                    />
                }
                {(tabSelected === "available" ? earnActivities.some((a: EarnActivityModel) => a.id === "twitter-share" && a.available)
                    : (tabSelected === "done" ? earnActivities.some((a: EarnActivityModel) => a.id === "twitter-share" && !a.available)
                        : earnActivities.some((a: EarnActivityModel) => a.id === "twitter-share"))) &&
                    <CompleteableEarnItemWithRedirect
                        earnActivity={earnActivities.find((a: EarnActivityModel) => a.id === "twitter-share") ?? {}}
                        earnType="TwitterShare"
                    />
                }

                {filteredCustomActivities && filteredCustomActivities.map(activity => (
                    (tabSelected === "available" ? activity.available
                        : (tabSelected === "done" ? !activity.available
                            : activity.available)) &&
                    <RepeatableEarnItem
                        earnActivity={activity}
                        earnType={"Custom"}
                    />
                ))}
            </ul>
        </React.Fragment>
    );
};

export default EarnList;
