import React from "react";
import { Outlet } from "react-router-dom";
import { BrandingProvider } from "../contexts/BrandingContext";
import { NavigationProvider } from "../contexts/NavigationContext";

const WidgetLayout = ({ children }) => {
    return (
        <React.Fragment>
            <div className="wrapper">
                <NavigationProvider>
                    <BrandingProvider>
                        {children}
                        <Outlet />
                    </BrandingProvider>
                </NavigationProvider>
            </div>
        </React.Fragment>
    );
};

export default WidgetLayout;
