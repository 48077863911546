import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ArrowUpRight, Check } from "react-feather";
import { CustomerPortalEarn, CustomerPortalWording, EarnActivityModel } from "../../../../../apiclient";
import { CustomerContext } from "../../../../../contexts/CustomerContext";
import Icon from '../../util/Icon';
import Editable from '../Editable';
interface EarnItemParams {
    earnActivity: EarnActivityModel;
    earnType: string;
}

const CompletableEarnItemWithRedirect = ({ earnActivity, earnType }: EarnItemParams) => {
    const context = useContext(CustomerContext);
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [earnMergeTags, setEarnMergeTags] = useState({});
    type EarnKey = keyof CustomerPortalEarn;
    useEffect(() => {
        setEarnMergeTags({ pointsEarned: earnActivity.points, completedDate: earnActivity.lastRewarded ? new Date(earnActivity.lastRewarded).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' }) : "" });
        setFirstLoad(false);
    }, [earnActivity.lastRewarded, earnActivity.points]);

    const handleClick = () => {
        if (earnActivity.available && earnActivity.url && !loading) {
            setLoading(true);
            window.open(earnActivity.url + "?redirect=true", "_blank");
            window.onfocus = function (e) {
                context.refreshCustomer(() => setLoading(false));
                window.onfocus = function () { }; //reset onfocus event
            };
        }
    };

    return (
        <React.Fragment>
            {!firstLoad && loading &&
                <li className={`cp-item`} >
                    <div className="cp-spinner-container">
                        <Spinner animation="border" role="status" size="sm"><span className="visually-hidden">Loading...</span></Spinner>
                    </div >
                </li>
            }
            {!firstLoad && !loading &&
                <li className={`cp-item ${!earnActivity.available && 'complete'}`} onClick={handleClick}>
                    {context.settings?.content?.icon?.useEarnIcon &&
                        <figure className="cp-icon-container">
                            <Icon icon={earnType} />
                        </figure>
                    }
                    <div className="cp-item-content">
                        <h6>
                            <Editable
                                id={`marsello-earn-heading-${earnType}`}
                                display="block"
                                initialContent={context.settings?.content?.wording?.earn?.[("heading" + earnType) as EarnKey]}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => {
                                    if (s.earn?.[("heading" + earnType) as EarnKey] != null) {
                                        s.earn[("heading" + earnType) as EarnKey] = c;
                                    }
                                    return s;
                                }}
                            />
                        </h6>
                        {earnActivity.available ?
                            <small>
                                <Editable
                                    id={`marsello-earn-label-available-${earnType}`}
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.labelTemplateAvailable}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelTemplateAvailable: c } } }}
                                />
                            </small>
                            :
                            <span className="cp-text-sm">
                                <Editable
                                    id={`marsello-earn-label-complete-${earnType}`}
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.labelTemplateComplete}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelTemplateComplete: c } } }}
                                />
                            </span>
                        }
                    </div>
                    {!earnActivity.available ?
                        <Check
                            className="cp-check"
                        />
                        :
                        <ArrowUpRight
                            className="cp-icon"
                        />
                    }
                </li>
            }
        </React.Fragment>
    );
};

export default CompletableEarnItemWithRedirect;
