import React, { useContext, useState } from "react";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import ActivityList from '../components/ActivityList';
import Editable from "../components/Editable";
import Footer from '../components/Footer';

function Activity() {
    const [tabSelected, setTabSelected] = useState("all");
    const context = useContext(CustomerContext);
    return (
        <React.Fragment>
            {context.customer != null ?
                <div className="cp-content">
                    {/* <ul className="cp-pills">
                        <li className={`cp-pill ${tabSelected === "all" && "selected"}`} onClick={() => !context.isEditable && setTabSelected("all")}>
                            <Editable
                                display="block"
                                initialContent={settings.content.wording.activity.pillAll}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergetags?.default }}
                                settings={settings}
                            />
                        </li>
                        <li className={`cp-pill ${tabSelected === "receipts" && "selected"}`} onClick={() => !context.isEditable && setTabSelected("receipts")}>
                            <Editable
                                display="block"
                                initialContent={settings.content.wording.activity.pillReceipts}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergetags?.default }}
                                settings={settings}
                            />
                        </li>
                        <li className={`cp-pill ${tabSelected === "redemptions" && "selected"}`} onClick={() => !context.isEditable && setTabSelected("redemptions")}>
                            <Editable
                                display="block"
                                initialContent={settings.content.wording.activity.pillRedemptions}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergetags?.default }}
                                settings={settings}
                            />
                        </li>
                    </ul> */}
                    <ActivityList
                        tabSelected={tabSelected}
                    />
                    <Footer />
                </div>
                :
                <div className="m-3 text-lg text-center">
                    <Editable
                        id="marsello-page-not-visible-activity"
                        display="block"
                        initialContent={context.settings?.content?.wording?.account?.pageNotAvailableLabel}
                        isEditable={context.isEditable}
                        mergeTags={{ ...context.mergeTags?.default }}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, pageNotAvailableLabel: c } } }}
                    />
                </div>
            }
        </React.Fragment>
    )
}

export default Activity;
