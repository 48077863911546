import React, { useContext, useState } from "react";
import { Check, ChevronRight, Copy } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import EarnList from '../components/EarnList';
import Editable from '../components/Editable';
import Footer from '../components/Footer';
import RedeemList from '../components/RedeemList';
import TierList from '../components/TierList';
import UserProgress from '../components/UserProgress';
import Icon from '../util/Icon';

function Home() {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    const [copiedReferralLink, setCopiedReferralLink] = useState(false);

    const handleCopyToClipboard = () => {
        const referralLink = getReferralLink();
        navigator.clipboard.writeText(referralLink);
        setCopiedReferralLink(true);
        setTimeout(() => { setCopiedReferralLink(false) }, 3000);
    };

    // Helper class for debugging
    const getReferralLink = () => {
        return context.customer?.referralLink ?? "";
    }
    const renderJoinCard = () => {
        let result = <React.Fragment></React.Fragment>;
        switch (context.settings?.content?.card?.visitorJoinOption) {
            case "Benefits":
                result = (
                    <div className="cp-card">
                        <div className="cp-card-header cp-card-header-border">
                            {(context.isEditable || context.settings?.content?.wording?.home?.joinCardHeading !== "") &&
                                <h4>
                                    <Editable
                                        id="marsello-home-join-card-heading"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.joinCardHeading}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardHeading: c } } }}
                                    />
                                </h4>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.joinCardIntroduction !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-home-join-card-introduction"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.joinCardIntroduction}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardIntroduction: c } } }}
                                    />
                                </p>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.joinCardButton !== "") &&
                                <div className="cp-btn" onClick={() => !context.isEditable && navigation.setPage("Signup")}>
                                    <Editable
                                        id="marsello-home-join-card-button"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.joinCardButton}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardButton: c } } }}
                                    />
                                </div>
                            }
                        </div>
                        <div className="cp-card-content">
                            <h6>
                                <Editable
                                    id="marsello-home-join-card-benefit-sub-heading"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context.settings?.content?.wording?.home?.joinCardBenefitSubheading}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardBenefitSubheading: c } } }}
                                />
                            </h6>
                            <ul className="cp-feature-list">
                                <li className="cp-feature-item">
                                    <Check className="cp-feature-check" />
                                    <span className="cp-feature-content">
                                        <Editable
                                            id="marsello-home-join-card-first-benefit"
                                            display="block"
                                            isEditable={context.isEditable}
                                            initialContent={context.settings?.content?.wording?.home?.joinCardFirstBenefit}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardFirstBenefit: c } } }}
                                        />
                                    </span>
                                </li>
                                <li className="cp-feature-item">
                                    <Check className="cp-feature-check" />
                                    <span className="cp-feature-content">
                                        <Editable
                                            id="marsello-home-join-card-second-benefit"
                                            display="block"
                                            isEditable={context.isEditable}
                                            initialContent={context.settings?.content?.wording?.home?.joinCardSecondBenefit}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardSecondBenefit: c } } }}
                                        />
                                    </span>
                                </li>
                                <li className="cp-feature-item">
                                    <Check className="cp-feature-check" />
                                    <span className="cp-feature-content">
                                        <Editable
                                            id="marsello-home-join-card-third-benefit"
                                            display="block"
                                            isEditable={context.isEditable}
                                            initialContent={context.settings?.content?.wording?.home?.joinCardThirdBenefit}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardThirdBenefit: c } } }}
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="cp-card-footer cp-card-footer-border">
                            <p>
                                {(context.isEditable || context.settings?.content?.wording?.home?.joinCardSigninLabel !== "") &&
                                    <Editable
                                        id="marsello-home-join-card-signin"
                                        display="inline"
                                        initialContent={context.settings?.content?.wording?.home?.joinCardSigninLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardSigninLabel: c } } }}
                                    />
                                }
                                {(context.isEditable || context.settings?.content?.wording?.home?.joinCardSigninLink !== "") &&
                                    <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("Signin")}>
                                        <Editable
                                            id="marsello-home-join-card-signin-link"
                                            display="inline"
                                            initialContent={context.settings?.content?.wording?.home?.joinCardSigninLink}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardSigninLink: c } } }}
                                        />
                                    </span>
                                }
                            </p>
                        </div>
                    </div>
                );
                break;
            default:
                result = (
                    <div className="cp-card">
                        <div className="cp-card-header cp-card-header-border">
                            {(context.isEditable || context.settings?.content?.wording?.home?.joinCardHeading !== "") &&
                                <h4>
                                    <Editable
                                        id="marsello-home-join-card-heading"
                                        display="block"
                                        isEditable={context.isEditable}
                                        initialContent={context.settings?.content?.wording?.home?.joinCardHeading}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardHeading: c } } }}
                                    />
                                </h4>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.joinCardIntroduction !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-home-join-card-introduciton"
                                        display="block"
                                        isEditable={context.isEditable}
                                        initialContent={context.settings?.content?.wording?.home?.joinCardIntroduction}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardIntroduction: c } } }}
                                    />
                                </p>
                            }
                            <div className="cp-btn" onClick={() => !context.isEditable && navigation.setPage("Signup")}>
                                <Editable
                                    id="marsello-home-join-card-button"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context.settings?.content?.wording?.home?.joinCardButton}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardButton: c } } }}
                                />
                            </div>
                            <p>
                                {(context.isEditable || context.settings?.content?.wording?.home?.joinCardSigninLabel !== "") &&
                                    <Editable
                                        id="marsello-home-join-card-sigin"
                                        isEditable={context.isEditable}
                                        initialContent={context.settings?.content?.wording?.home?.joinCardSigninLabel}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardSigninLabel: c } } }}
                                    />
                                }
                                {(context.isEditable || context.settings?.content?.wording?.home?.joinCardSigninLink !== "") &&
                                    <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("Signin")}>
                                        <Editable
                                            id="marsello-home-join-card-sigin-link"
                                            isEditable={context.isEditable}
                                            initialContent={context.settings?.content?.wording?.home?.joinCardSigninLink}
                                            mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, joinCardSigninLink: c } } }}
                                        />
                                    </span>
                                }
                            </p>
                        </div>
                    </div>
                );
                break;
        }
        return result;
    }

    const renderProgramButtons = () => {
        let result = (
            <div className="cp-row">
                <div className="cp-col">
                    <div className="cp-card h-100" onClick={() => !context.isEditable && navigation.setPage("Earn")}>
                        {context.settings?.content?.icon?.useEarnIcon &&
                            <div className="cp-card-header cp-card-header-icon">
                                <figure className="cp-icon-container">
                                    <Icon icon="Earn" />
                                </figure>
                            </div>
                        }
                        <div className="cp-card-content cp-card-content-link align-items-start">
                            {context.customer !== null ?
                                <hgroup className="cp-card-hgroup">
                                    {(context.isEditable || context.settings?.content?.wording?.home?.earnButtonHeadingCustomer !== "") &&
                                        <h4>
                                            <Editable
                                                id="marsello-home-earn-button-heading-customer"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.earnButtonHeadingCustomer}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnButtonHeadingCustomer: c } } }}
                                            />
                                        </h4>
                                    }
                                    {(context.isEditable || context.settings?.content?.wording?.home?.earnButtonSubheadingCustomer !== "") &&
                                        <p>
                                            <Editable
                                                id="marsello-home-earn-button-subheading-customer"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.earnButtonSubheadingCustomer}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnButtonSubheadingCustomer: c } } }}
                                            />
                                        </p>
                                    }
                                </hgroup>
                                :
                                <div className="cp-card-hgroup">
                                    {(context.isEditable || context.settings?.content?.wording?.home?.earnButtonHeadingVisitor !== "") &&
                                        <h4>
                                            <Editable
                                                id="marsello-home-earn-button-heading-visitor"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.earnButtonHeadingVisitor}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnButtonHeadingVisitor: c } } }}
                                            />
                                        </h4>
                                    }
                                    {(context.isEditable || context.settings?.content?.wording?.home?.earnButtonSubheadingVisitor !== "") &&
                                        <p>
                                            <Editable
                                                id="marsello-home-earn-button-subheading-visitor"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.earnButtonSubheadingVisitor}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnButtonSubheadingVisitor: c } } }}
                                            />
                                        </p>
                                    }
                                </div>
                            }
                            <ChevronRight className="cp-icon cp-icon-chevron" height="18" width="18" />
                        </div>
                    </div>
                </div>
                <div className="cp-col">
                    <div className="cp-card h-100" onClick={() => !context.isEditable && navigation.setPage("Redeem")}>
                        {context.settings?.content?.icon?.useRedeemIcon &&
                            <div className="cp-card-header cp-card-header-icon">
                                <figure className="cp-icon-container">
                                    <Icon icon="Redeem" />
                                </figure>
                            </div>
                        }
                        <div className="cp-card-content cp-card-content-link align-items-start">
                            {context.customer !== null ?
                                <hgroup className="cp-card-hgroup">
                                    {(context.isEditable || context.settings?.content?.wording?.home?.redeemButtonHeadingCustomer !== "") &&
                                        <h4>
                                            <Editable
                                                id="marsello-home-redeem-button-heading-customer"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.redeemButtonHeadingCustomer}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemButtonHeadingCustomer: c } } }}
                                            />
                                        </h4>
                                    }
                                    {(context.isEditable || context.settings?.content?.wording?.home?.redeemButtonSubheadingCustomer !== "") &&
                                        <p>
                                            <Editable
                                                id="marsello-home-redeem-button-subheading-customer"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.redeemButtonSubheadingCustomer}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemButtonSubheadingCustomer: c } } }}
                                            />
                                        </p>
                                    }
                                </hgroup>
                                :
                                <div className="cp-card-hgroup">
                                    {(context.isEditable || context.settings?.content?.wording?.home?.redeemButtonHeadingVisitor !== "") &&
                                        <h4>
                                            <Editable
                                                id="marsello-home-redeem-button-heading-visitor"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.redeemButtonHeadingVisitor}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemButtonHeadingVisitor: c } } }}
                                            />
                                        </h4>
                                    }
                                    {(context.isEditable || context.settings?.content?.wording?.home?.redeemButtonSubheadingVisitor !== "") &&
                                        <p>
                                            <Editable
                                                id="marsello-home-redeem-button-subheading-visitor"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.home?.redeemButtonSubheadingVisitor}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemButtonSubheadingVisitor: c } } }}
                                            />
                                        </p>
                                    }
                                </div>
                            }
                            <ChevronRight className="cp-icon cp-icon-chevron" height="18" width="18" />
                        </div>
                    </div>
                </div>
            </div>
        );
        return result;
    }

    const renderProgramSection = () => {
        let result = <React.Fragment></React.Fragment>;
        switch (context.settings?.content?.card?.customerProgramOption) {
            case "Both":
                result = (
                    <React.Fragment>
                        {renderProgramButtons()}
                    </React.Fragment>
                );
                break;
            case "Earn":
                result = (
                    <div className="cp-card" >
                        <header className="cp-card-header cp-card-header-border">
                            {(context.isEditable || context.settings?.content?.wording?.home?.earnListHeading !== "") &&
                                <h4>
                                    <Editable
                                        id="marsello-home-earn-list-heading"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.earnListHeading}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnListHeading: c } } }}
                                    />
                                </h4>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.earnListIntroduction !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-home-earn-list-introduciton"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.earnListIntroduction}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnListIntroduction: c } } }}
                                    />
                                </p>
                            }
                        </header>
                        <div className="cp-card-main">
                            <EarnList
                                length={3}
                                tabSelected={"available"} />
                        </div>
                        {(context.isEditable || context.settings?.content?.wording?.home?.earnListLink !== "") &&
                            <footer className="cp-card-footer cp-card-footer-border" onClick={() => !context.isEditable && navigation.setPage("Earn")}>
                                <div className="cp-link">
                                    <Editable
                                        id="marsello-home-earn-list-link"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.earnListLink}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, earnListLink: c } } }}
                                    />
                                </div>
                            </footer>
                        }
                    </div>
                );
                break;
            default:
                result = (
                    <div className="cp-card">
                        <div className="cp-card-header cp-card-header-border">
                            {(context.isEditable || context.settings?.content?.wording?.home?.redeemListHeading !== "") &&
                                <h4>
                                    <Editable
                                        id="marsello-home-redeem-list-heading"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.redeemListHeading}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemListHeading: c } } }}
                                    />
                                </h4>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.redeemListIntroduction !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-home-redeem-list-introduciton"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.redeemListIntroduction}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemListIntroduction: c } } }}
                                    />
                                </p>
                            }
                        </div>
                        <div className="cp-card-main">
                            <RedeemList
                                length={3}
                                tabSelected={"all"} />
                        </div>
                        {(context.isEditable || context.settings?.content?.wording?.home?.redeemListLink !== "") &&
                            <div className="cp-card-footer cp-card-footer-border" onClick={() => !context.isEditable && navigation.setPage("Redeem")}>
                                <div className="cp-link">
                                    <Editable
                                        id="marsello-home-redeem-list-link"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.redeemListLink}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemListLink: c } } }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                );
                break;
        }
        return result;
    }

    const renderRedeemCard = () => {
        let result = <React.Fragment></React.Fragment>;

        if (context?.customer?.rewards?.some(r => r.points === 0)) {
            result = (
                <React.Fragment>
                    <div className="cp-card" >
                        <div className="cp-card-content cp-card-content-link" onClick={() => !context.isEditable && navigation.setPage("Redeem")}>
                            {context.settings?.content?.icon?.useRedeemIcon &&
                                <figure className="cp-icon-container">
                                    <Icon icon="FreeShipping" />
                                </figure>
                            }
                            <div className="cp-item-content flex-grow-1">
                                <h4 className="mb-1">{context?.customer?.rewards?.find(r => r.points === 0)?.name}</h4>
                                <Editable
                                    id="marsello-home-redeem-card-coupon-text"
                                    display="block"
                                    className="mb-0"
                                    initialContent={context.settings?.content?.wording?.home?.redeemCardCouponText}
                                    isEditable={context.isEditable}
                                    mergeTags={{
                                        ...context.mergeTags?.default,
                                        "expiry": new Date(context?.customer?.rewards?.find(r => r.points === 0)?.expires ?? "").toLocaleString(undefined, { day: 'numeric', month: 'short', year: 'numeric' }),
                                        "daysUntilExpiry": Math.round(Math.abs((new Date(context?.customer?.rewards?.find(r => r.points === 0)?.expires ?? "").valueOf() - new Date().valueOf()) / (24 * 60 * 60 * 1000))),
                                        "rewardDescription": context?.customer?.rewards?.find(r => r.points === 0)?.description
                                    }} fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemCardCouponText: c } } }}
                                />
                            </div>
                            <ChevronRight className="cp-icon cp-icon-chevron" height="18" width="18" />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else if (context?.customer?.rewards?.some(r => (r.points ?? 0) <= (context.customer?.balance ?? 0))) {
            result = (
                <React.Fragment>
                    <div className="cp-card">
                        <div className="cp-card-content cp-card-content-link" onClick={() => !context.isEditable && navigation.setPage("Redeem")}>
                            {context.settings?.content?.icon?.useRedeemIcon &&
                                <figure className="cp-icon-container">
                                    <Icon icon="FreeShipping" />
                                </figure>
                            }
                            <div className="cp-item-content flex-grow-1">
                                <h4 className="mb-1">{context?.customer?.rewards?.findLast(r => (r.points ?? 0) <= (context.customer?.balance ?? 0))?.name}</h4>
                                <Editable
                                    id="marsello-home-redeem-card-reward-text"
                                    display="block"
                                    className="mb-0"
                                    initialContent={context.settings?.content?.wording?.home?.redeemCardRewardText}
                                    isEditable={context.isEditable}
                                    mergeTags={{
                                        ...context.mergeTags?.default,
                                        "pointCost": context?.customer?.rewards?.findLast(r => (r.points ?? 0) <= (context.customer?.balance ?? 0))?.points,
                                        "rewardDescription": context?.customer?.rewards?.findLast(r => (r.points ?? 0) <= (context.customer?.balance ?? 0))?.description
                                    }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemCardRewardText: c } } }}
                                />
                            </div>
                            <ChevronRight className="cp-icon cp-icon-chevron" height="18" width="18" />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else if (context?.customer?.rewards?.at(0)) {
            result = (
                <React.Fragment>
                    <div className="cp-card">
                        <div className="cp-card-content cp-card-content-link">
                            {context.settings?.content?.icon?.useRedeemIcon &&
                                <figure className="cp-icon-container">
                                    <Icon icon="FreeShipping" />
                                </figure>
                            }
                            <div className="cp-item-content flex-grow-1">
                                <h4 className="mb-1">{context?.customer?.rewards[0]?.name}</h4>
                                <Editable
                                    id="marsello-home-redeem-card-default-text"
                                    display="block"
                                    className="mb-0"
                                    initialContent={context.settings?.content?.wording?.home?.redeemCardDefaultText}
                                    isEditable={context.isEditable}
                                    mergeTags={{
                                        ...context.mergeTags?.default,
                                        "pointsToNextReward": (context?.customer?.rewards[0]?.points ?? 0) - (context.customer?.balance ?? 0),
                                        "rewardDescription": context?.customer?.rewards[0]?.description
                                    }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, redeemCardDefaultText: c } } }}
                                />
                            </div>
                            {/*
                                {renderProgress()}
                            */}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return result;
    }

    const renderReferralCard = () => {
        let result = <React.Fragment></React.Fragment>;
        if (!!context.customer) {
            result = (
                <div className="cp-card">
                    <div className="cp-card-header">
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardHeadingCustomer !== "") &&
                            <h4>
                                <Editable
                                    id="marsello-home-referral-heading-customer"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardHeadingCustomer}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardHeadingCustomer: c } } }}
                                />
                            </h4>
                        }
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardIntroductionCustomer !== "") &&
                            <p>
                                <Editable
                                    id="marsello-home-referral-card-introduciton-customer"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardIntroductionCustomer}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardIntroductionCustomer: c } } }}
                                />
                            </p>
                        }
                        <div className="cp-field" onClick={handleCopyToClipboard} >
                            <span className="cp-field-label" id="marsello-referral-link">{copiedReferralLink ? "Added to clipboard" : getReferralLink()}</span>
                            <Copy className="cp-field-icon" />
                        </div>
                        <div className="align-items-center d-flex justify-content-between mb-2">
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardInstructions !== "") &&
                                <small className="cp-text-start">
                                    <Editable
                                        id="marsello-home-referral-card-instructions"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.referralCardInstructions}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardInstructions: c } } }}
                                    />
                                </small>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardProgress !== "") &&
                                <small className="cp-text-end">
                                    <strong>
                                        <Editable
                                            id="marsello-home-referral-card-progress"
                                            display="block"
                                            initialContent={context.settings?.content?.wording?.home?.referralCardProgress}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardProgress: c } } }}
                                        />
                                    </strong>
                                </small>
                            }
                        </div>
                    </div>
                </div >
            );
        } else {
            result = (
                <div className="cp-card">
                    <div className="cp-card-header">
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardHeadingVisitor !== "") &&
                            <h4>
                                <Editable
                                    id="marsello-home-referral-card-heading-visitor"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardHeadingVisitor}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardHeadingVisitor: c } } }}
                                />
                            </h4>
                        }
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardIntroductionVisitor !== "") &&
                            <p>
                                <Editable
                                    id="marsello-home-referral-card-introduciton-visitor"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardIntroductionVisitor}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardIntroductionVisitor: c } } }}
                                />
                            </p>
                        }
                        <div className="cp-btn" onClick={() => !context.isEditable && navigation.setPage("Signup")}>
                            <Editable
                                id="marsello-home-referral-card-button"
                                display="block"
                                isEditable={context.isEditable}
                                initialContent={context.settings?.content?.wording?.home?.referralCardButton}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardButton: c } } }}
                            />
                        </div>
                        <p>
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardSigninLabel !== "") &&
                                <Editable
                                    id="marsello-home-referral-card-sigin"
                                    isEditable={context.isEditable}
                                    initialContent={context.settings?.content?.wording?.home?.referralCardSigninLabel}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardSigninLabel: c } } }}
                                />
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardSigninLink !== "") &&
                                <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("Signin")}>
                                    <Editable
                                        id="marsello-home-referral-card-sigin-link"
                                        isEditable={context.isEditable}
                                        initialContent={context.settings?.content?.wording?.home?.referralCardSigninLink}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardSigninLink: c } } }}
                                    />
                                </span>
                            }
                        </p>
                    </div>
                </div>
            );
        }
        return result;
    }

    const renderTiersCard = () => {
        let result = <React.Fragment></React.Fragment>;
        if (context.customer != null) {
            result = (
                <UserProgress />
            );
        } else {
            result = (
                <div className="cp-card">
                    <div className="cp-card-header cp-card-header-border">
                        {(context.isEditable || context.settings?.content?.wording?.home?.tierCardHeading !== "") &&
                            <h4>
                                <Editable
                                    id="marsello-home-tier-card-heading"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.tierCardHeading}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, tierCardHeading: c } } }}
                                />
                            </h4>
                        }
                        {(context.isEditable || context.settings?.content?.wording?.home?.tierCardIntroduction !== "") &&
                            <p>
                                <Editable
                                    id="marsello-home-tier-card-introduciton"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.tierCardIntroduction}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, tierCardIntroduction: c } } }}
                                />
                            </p>
                        }
                    </div>
                    <div className="cp-card-content cp-card-content-end cp-card-content-flush">
                        <TierList />
                    </div>
                </div>
            );
        }
        return result;
    }

    return (
        <div className="cp-content">
            {!!context.customer ?
                <React.Fragment>
                    {context.settings?.content?.card?.customerRedemptionEnabled &&
                        <React.Fragment>
                            {renderRedeemCard()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.customerProgramEnabled &&
                        <React.Fragment>
                            {renderProgramSection()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.customerTiersEnabled &&
                        <React.Fragment>
                            {renderTiersCard()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.customerReferralEnabled && context?.account?.earnActivities?.some(e => e.id === "referral" && e.available) &&
                        <React.Fragment>
                            {renderReferralCard()}
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    {context.settings?.content?.card?.visitorJoinEnabled &&
                        <React.Fragment>
                            {renderJoinCard()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.visitorProgramEnabled &&
                        <React.Fragment>
                            {renderProgramButtons()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.visitorTiersEnabled &&
                        <React.Fragment>
                            {renderTiersCard()}
                        </React.Fragment>
                    }
                    {context.settings?.content?.card?.visitorReferralEnabled &&
                        <React.Fragment>
                            {renderReferralCard()}
                        </React.Fragment>
                    }
                </React.Fragment>
            }
            <Footer />
        </div>
    )
}

export default Home;
