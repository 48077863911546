import React, { useContext, useEffect, useState } from "react";
import { CustomerPortalWording, TierWithRewardsModel } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from './Editable';

const UserProgressBar = ({ selectedTier }: { selectedTier: TierWithRewardsModel | undefined }) => {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    const [nextTier, setNextTier] = useState(selectedTier ?? context.account?.tiers?.find(t => (t.thresholdValue ?? 2) > (context.customer?.tierProgressBalance ?? 1)));
    const [tierMergeTags, setTierMergeTags] = useState({
        currentTier: context.customer?.tierName,
        nextTierName: nextTier?.name,
        nextTierThresholdValue: nextTier?.thresholdValue,
        pointsToNextTier: (nextTier?.thresholdValue ?? 1) - (context.customer?.tierProgressBalance ?? 0)
    });

    useEffect(() => {
        let updatedNextTier = selectedTier ?? context.account?.tiers?.find(t => (t.thresholdValue ?? 2) > (context.customer?.tierProgressBalance ?? 1))
        setNextTier(updatedNextTier);
        setTierMergeTags({
            currentTier: context.customer?.tierName,
            nextTierName: updatedNextTier?.name,
            nextTierThresholdValue: updatedNextTier?.thresholdValue,
            pointsToNextTier: (updatedNextTier?.thresholdValue ?? 1) - (context.customer?.tierProgressBalance ?? 0) < 0 ? 0
                : (updatedNextTier?.thresholdValue ?? 1) - (context.customer?.tierProgressBalance ?? 0)
        });
    }, [context.account?.tiers, context.customer?.tierName, context.customer?.tierProgressBalance, selectedTier])


    return (
        <React.Fragment>
            {nextTier ? (navigation.page === 'Home' || navigation.page === 'More' || navigation.page === 'VIP Program') ?
                <div className="cp-card-content">
                    <div className="align-items-center d-flex justify-content-between">
                        {(context.isEditable || context.settings?.content?.wording?.userProgress?.progressBarLabel !== "") &&
                            <strong>
                                <Editable
                                    id="marsello-user-progress-bar-label"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.userProgress?.progressBarLabel}
                                    isEditable={context.isEditable}
                                    mergeTags={{
                                        ...context.mergeTags?.default,
                                        ...context.mergeTags?.customer,
                                        ...tierMergeTags
                                    }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, userProgress: { ...s.userProgress, progressBarLabel: c } } }}
                                />
                            </strong>
                        }
                        <span>{context.customer?.tierProgressBalance}/{nextTier?.thresholdValue}</span>
                    </div>
                    <div className="cp-progress">
                        <div className="cp-progress-bar" style={{
                            width: `calc(100% / ${((nextTier?.thresholdValue ?? 1) / (context.customer?.tierProgressBalance ?? 1)) < 1 ? 1
                                : (nextTier?.thresholdValue ?? 1) / (context.customer?.tierProgressBalance ?? 1)})`
                        }}></div>
                    </div>
                    {(context.isEditable || context.settings?.content?.wording?.userProgress?.tierRequirements !== "") &&
                        <p className="cp-secondary-text">
                            <Editable
                                id="marsello-user-progres-tier-requirements"
                                display="block"
                                initialContent={context.settings?.content?.wording?.userProgress?.tierRequirements}
                                isEditable={context.isEditable}
                                mergeTags={{
                                    ...context.mergeTags?.default,
                                    ...context.mergeTags?.customer,
                                    ...tierMergeTags
                                }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, userProgress: { ...s.userProgress, tierRequirements: c } } }}
                            />
                        </p>
                    }
                </div>
                :
                //nextTier but not on specified pages
                <div className="cp-card-content">
                    <div className="cp-progress">
                        <div className="cp-progress-bar" style={{ width: `calc(100% / ${(nextTier?.thresholdValue ?? 1) / (context.customer?.tierProgressBalance ?? 1)})` }}></div>
                    </div>
                    <div className="align-items-center d-flex justify-content-between">
                        {(context.isEditable || context.settings?.content?.wording?.userProgress?.progressBarLabel !== "") &&
                            <strong>
                                <Editable
                                    id="marsello-user-progress-bar-label"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.userProgress?.progressBarLabel}
                                    isEditable={context.isEditable}
                                    mergeTags={{
                                        ...context.mergeTags?.default,
                                        ...context.mergeTags?.customer,
                                        ...tierMergeTags
                                    }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, userProgress: { ...s.userProgress, progressBarLabel: c } } }}
                                />
                            </strong>
                        }
                        <span>{context.customer?.tierProgressBalance}/{nextTier?.thresholdValue}</span>
                    </div>
                </div>
                :
                // not nextTier so do not display
                <React.Fragment />
            }
        </React.Fragment>
    );
};

export default UserProgressBar;
