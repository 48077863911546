import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import WidgetLayout from "./layouts/WidgetLayout";
import Widget from './pages/customerPortal/portal/Widget';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById('marsello-portal-root');

// this is an empty div just so we know we are in widget mode
const widgetElement = document.getElementById("marsello-widget-root");

if (widgetElement) {
    ReactDOM.render(
        <WidgetLayout>
            <Widget />
        </WidgetLayout>,
        widgetElement);
}
else {
    ReactDOM.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
