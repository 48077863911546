import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { CustomerApi, CustomerPortalWording, SignUpPostRequestModel } from '../../../../apiclient';
import { apiConfig } from '../../../../config';
import { CustomerContext } from '../../../../contexts/CustomerContext';
import { NavigationContext } from '../../../../contexts/NavigationContext';
import Editable from '../components/Editable';
import Footer from '../components/Footer';
import { inIframe } from '../util/Helpers';
import Icon from '../util/Icon';
import iconApple from '../util/img/icon_social-btn_apple.svg';
import iconFB from '../util/img/icon_social-btn_fb.svg';
import iconGoogle from '../util/img/icon_social-btn_google.svg';
function Signin({ isSignup }: { isSignup: boolean }) {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    let customerApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl)
    const [data, setData] = useState({ email: null, verificationSent: false });
    const [error, setError] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [showSignup, setShowSignup] = useState(isSignup);
    let loginWindow: Window | null = null;
    let settings = context.settings;

    useEffect(() => {
        setLoading(false);
        // should only be on this page and authenticated if it is a signup
        setShowSignup(isSignup || context.authenticated)

        if (inIframe() && !context.isEditable) {
            setLoading(true);
            //go to signin page in the parent frame
            window.parent.postMessage(isSignup ? "signup" : "signin", "*");
        }
        if (context.authenticated && !context.account?.requireEmail) {
            // signed in and not apple so can continue to the app
            submitForm();
        }
    }, [isSignup, context.authenticated])

    useEffect(() => {
        // ensure that when we leave the page it does not remain loading forever
        return () => { setLoading(false); }
    }, [])


    const openLoginPopup = (path: string) => {
        if (context.isEditable) {
            return;
        }
        let loginUrl = context.account?.loginUrl;
        let url = loginUrl + path;
        loginWindow = window.open(url, "loginWindow", "width=450,height=650directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no");
        window.onfocus = function () {
            if (loginWindow) window.location.reload();
        }
        return true;
    };

    const handleChange = (event: any) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };


    const submitForm = () => {
        const customer_id = localStorage.getItem("customer_id") ?? "";
        if (!loading) {
            setLoading(true);
            // Call api to create new customer
            customerApi
                .customerPost('1.0', { acceptMarketing: true, customerId: customer_id, unverifiedEmail: data.email } as SignUpPostRequestModel)
                .then((res) => {
                    if (res.status === 202) {
                        setData({ ...data, verificationSent: true }) // because apple is special
                        setLoading(false);
                    } else {
                        context.refreshCustomer(() => { navigation.setPage("Home") })
                    }
                }).catch((err: any) => {
                    setError(err);
                    setLoading(false);
                })
        }
    }

    return (
        <div className="cp-content">
            <div className="cp-card">
                <div className="cp-card-header cp-card-header-border">
                    {!showSignup && (context.isEditable || settings?.content?.wording?.signin?.heading !== "") &&
                        <h4>
                            <Editable
                                id="marsello-signin-heading"
                                display="block"
                                isEditable={context.isEditable}
                                initialContent={settings?.content?.wording?.signin?.heading}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, heading: c } } }}
                            />
                        </h4>
                    }
                    {!showSignup && (context.isEditable || settings?.content?.wording?.signin?.introduction !== "") &&
                        <p>
                            <Editable
                                id="marsello-signin-introduction"
                                display="block"
                                isEditable={context.isEditable}
                                initialContent={settings?.content?.wording?.signin?.introduction}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, introduction: c } } }}
                            />
                        </p>
                    }
                    {showSignup && (context.isEditable || settings?.content?.wording?.signup?.heading !== "") &&
                        <h4>
                            <Editable
                                id="marsello-signin-heading"
                                display="block"
                                isEditable={context.isEditable}
                                initialContent={settings?.content?.wording?.signup?.heading}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signin, heading: c } } }}
                            />
                        </h4>
                    }
                    {showSignup && (context.isEditable || settings?.content?.wording?.signup?.introduction !== "") &&
                        <p>
                            <Editable
                                id="marsello-signup-introduction"
                                display="block"
                                isEditable={context.isEditable}
                                initialContent={settings?.content?.wording?.signup?.introduction}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, introduction: c } } }}
                            />
                        </p>
                    }
                </div>
                <div className="cp-card-content">
                    {(!context.authenticated && !loading) ?
                        <ul className="cp-social-login-list">
                            <li className="cp-social-login-item">
                                <div className="cp-btn cp-btn-social cp-btn-google" onClick={function () { !context.isEditable && openLoginPopup("/Google") }}>
                                    <img className="cp-btn-social-icon" height="16" src={iconGoogle} width="16" alt="Google Logo" />
                                    {isSignup ?
                                        <Editable
                                            id="marsello-signup-google"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signup?.googleSignup}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, googleSignup: c } } }}
                                        />
                                        : <Editable
                                            id="marsello-signin-google"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signin?.googleLogin}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, googleLogin: c } } }}
                                        />
                                    }
                                </div>
                            </li>
                            <li className="cp-social-login-item" >
                                <div className="cp-btn cp-btn-social cp-btn-facebook" onClick={function () { !context.isEditable && openLoginPopup("/Facebook") }}>
                                    <img className="cp-btn-social-icon" height="16" src={iconFB} width="16" alt="Facebook Logo" />
                                    {isSignup ?
                                        <Editable
                                            id="marsello-signup-facebook"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signup?.facebookSignup}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, facebookSignup: c } } }}
                                        />
                                        : <Editable
                                            id="marsello-signin-facebook"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signin?.facebookLogin}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, facebookLogin: c } } }}
                                        />
                                    }
                                </div>
                            </li>
                            <li className="cp-social-login-item">
                                <div className="cp-btn cp-btn-social cp-btn-apple" onClick={function () { !context.isEditable && openLoginPopup("/Apple") }}>
                                    <img className="cp-btn-social-icon" height="16" src={iconApple} width="16" alt="Apple Logo" />
                                    {isSignup ?
                                        <Editable
                                            id="marsello-signup-apple"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signup?.appleSignup}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, appleSignup: c } } }}
                                        />
                                        : <Editable
                                            id="marsello-signin-apple"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signin?.appleLogin}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, appleLogin: c } } }}
                                        />
                                    }
                                </div>
                            </li>
                            <li className="cp-social-login-item">
                                <div className="cp-btn cp-btn-social" onClick={function () { !context.isEditable && navigation.setPage("EmailLogin") }}>
                                    <div className="cp-btn-social-icon"><Icon icon="Envelope" /></div>
                                    {isSignup ?
                                        <Editable
                                            id="marsello-signup-email"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signup?.emailSignup}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, emailSignup: c } } }}
                                        />
                                        : <Editable
                                            id="marsello-signin-email"
                                            display="block"
                                            className="cp-btn-social-label"
                                            isEditable={context.isEditable}
                                            initialContent={settings?.content?.wording?.signin?.emailLogin}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, emailLogin: c } } }}
                                        />
                                    }
                                </div>
                            </li>
                        </ul>
                        :
                        <div hidden={(data.verificationSent)}>
                            <div className="form-floating">
                                <input
                                    placeholder="Email address"
                                    type="email"
                                    id="signupEmail"
                                    className="cp-form-control form-control"
                                    name="email"
                                    hidden={(!context?.account?.requireEmail)}
                                    required={(context?.account?.requireEmail)}
                                    onChange={handleChange}
                                />
                                <label htmlFor="signupEmail" hidden={(!context?.account?.requireEmail)}>Email address</label>
                                <span style={{ color: "#d9534f" }}>{error.unverifiedEmail}</span>
                            </div>
                            <br />
                            <div className="cp-spinner-container">
                                <Spinner animation="border" role="status" hidden={!loading} />
                                <div className={'cp-btn'} hidden={loading} onClick={submitForm}>Create account</div>
                                <br />
                                <br />
                                <a hidden={loading} href="/account/logout">Cancel</a>
                            </div>

                        </div>}
                    {(data.verificationSent) &&
                        <div>
                            <div className="form-floating">
                                <span>Please check your email and click the link to start earning points.</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="cp-card-footer cp-card-footer-border">
                    <p>
                        {showSignup && (context.isEditable || settings?.content?.wording?.signup?.finePrint !== "") &&
                            <Editable
                                id="marsello-signup-fineprint"
                                display="block"
                                initialContent={settings?.content?.wording?.signup?.finePrint}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signup: { ...s.signup, finePrint: c } } }}
                            />
                        }
                        {!showSignup && (context.isEditable || settings?.content?.wording?.signin?.signupLabel !== "") &&
                            <Editable
                                id="marsello-signup-label"
                                display="inline"
                                initialContent={settings?.content?.wording?.signin?.signupLabel}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, signupLabel: c } } }}
                            />
                        }
                        {!showSignup && (context.isEditable || settings?.content?.wording?.signin?.signupLink !== "") &&
                            <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("Signup")}>
                                <Editable
                                    id="marsello-signup-link"
                                    display="inline"
                                    initialContent={settings?.content?.wording?.signin?.signupLink}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.signin, signupLink: c } } }}
                                />
                            </span>
                        }
                    </p>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Signin;
