import moment from 'moment';
import React, { useContext, useState } from "react";
import {
    Card
} from "react-bootstrap";
import { CustomerModel } from "../../../apiclient";
import CustomerDOBComponent from "../../../components/CustomerDOBComponent";
import NotyfContext from "../../../contexts/NotyfContext";
import ManualAdjustmentModalComponent from "./ManualAdjustmentModalComponent";
import './light.scss';

var md5 = require('md5');

interface CustomerDetailProps {
    customer: CustomerModel;
}

const CustomerDetailsComponent = ({ customer }: CustomerDetailProps) => {
    const [isLoading, setLoading] = useState(true as boolean);
    const [isPointsAdjustModalOpen, setIsPointsAdjustModalOpen] = useState(false);
    const [isEditCustomerDetailsModalOpen, setIsEditCustomerDetailsModalOpen] = useState(false);
    const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
    const [isMarketingPreferencesModalOpen, setIsMarketingPreferencesModalOpen] = useState(false);
    const [isUpdateVipTierModalOpen, setIsUpdateVipTierModalOpen] = useState(false);
    const [model, setModel] = useState(customer);
    const notyf = useContext(NotyfContext);

    const showEditCustomerDetailsModal = () => {
        setIsEditCustomerDetailsModalOpen(true);
    };

    const showAdjustPointsModal = () => {
        setIsPointsAdjustModalOpen(true);
    };

    const showMarketingPreferencesModal = () => {
        setIsMarketingPreferencesModalOpen(true);
    };

    const updatePointsBalance = (newBalance: number) => {
        model.balance = newBalance;
        setModel({
            ...model,
            balance: newBalance,
        });
    };

    const renderDetailsCard = () => {
        return (
            <Card>
                <Card.Body className="text-center">
                    <img
                        src={`https://www.gravatar.com/avatar/${md5(customer.email ?? "emptyaccount")}?d=https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png`}
                        alt={model.firstName + " " + model.lastName}
                        className="img-fluid rounded-circle mb-2"
                        width="128"
                        height="128"
                    />
                    <Card.Title className="mb-0">{model.firstName + " " + model.lastName}</Card.Title>
                    <div className="text-muted mb-2">{model.email}</div>
                    <div className="text-muted mb-2">{model.phone}</div>
                    <h4 className="mb-2">
                        {model.balance &&
                            model.balance.toLocaleString()}{" "}
                        {"points"}
                    </h4>
                </Card.Body>
                <hr className="my-0" />
                <Card.Body>
                    <Card.Title>Details</Card.Title>
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Birthday:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    <CustomerDOBComponent birthdateString={model.birthday as string} />
                                </strong>
                            </div>
                        </li>
                        {model.gender && (
                            <li className="mb-1">
                                <span className="m-b-10 font-size-14 m-l-5">
                                    Gender:
                                </span>
                                <div className="float-end">
                                    <strong className=" font-size-15">
                                        {model.gender}
                                    </strong>
                                </div>
                            </li>
                        )}
                    </ul>
                </Card.Body>
                <hr className="my-0" />
                <Card.Body>
                    <Card.Title>Loyalty</Card.Title>
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Tier:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.tierName ?? "Member"}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Member since:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {moment.utc(model.createdAt).local().format('Do MMM YYYY')}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Last visited:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">

                                    {model.lastVisited !== null ?
                                        moment.utc(model.lastVisited).local().format('Do MMM YYYY')
                                        : "Never"}
                                </strong>
                            </div>
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        )
    }

    return (
        <React.Fragment>
            {/* <div><pre>{JSON.stringify(props?.posContextData, null, 2)}</pre></div> */}
            {/* <div><pre>{JSON.stringify(props?.posAccount, null, 2)}</pre></div> */}
            {
                renderDetailsCard()
            }
            <ManualAdjustmentModalComponent
                isOpen={isPointsAdjustModalOpen}
                updatePointsBalance={updatePointsBalance}
                onHide={() => setIsPointsAdjustModalOpen(false)}
                pointsBalance={model.balance}
                firstname={model.firstName}
            />
        </React.Fragment>
    );
};

export default CustomerDetailsComponent;
