
import React, { useContext, useEffect, useState } from "react";
import { Copy, X } from "react-feather";
import QRCode from "react-qr-code";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';

function ClaimPopup({ isOnline }: { isOnline: boolean }) {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    const [instore, setInstore] = useState(!isOnline);
    const [copied, setCopied] = useState(false);

    let reward = context.selectedReward;
    let isCustomReward = reward.discountType === "custom-reward";
    let rewardMergeTags = { minimumSpend: reward.minimumSpend }

    useEffect(() => {
        setInstore(!isOnline);
    }, [isOnline]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(reward.redeemCode ?? "").then(() => {
            setCopied(true);
            setTimeout(() => { setCopied(false) }, 3000);
        });
    };

    return (
        <React.Fragment>
            <div className="cp-popup-container">
                <div className="cp-popup cp-popup-claim">
                    <React.Fragment>
                        <div className="cp-popup-header cp-popup-header-border">
                            <X className="cp-close-btn" onClick={() => !context.isEditable && navigation.setPage("Redeem")} />
                        </div>
                        <hgroup className="cp-popup-hgroup">
                            <h4>{reward.name}</h4>
                            <p>
                                {reward.points} {context?.mergeTags?.default?.pointsLabel} {" "}&middot;{" "}
                                <Editable
                                    id="marsello-claim-popup-spend"
                                    display="inline"
                                    initialContent={context.settings?.content?.wording?.claim?.spendThreshold}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...rewardMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, claim: { ...s.claim, spendThreshold: c } } }}
                                />
                            </p>
                            {reward.terms != null &&
                                <small className="cp-secondary-text">{reward.terms}</small>
                            }
                            {context.settings?.content?.useCustomerCard && (!isCustomReward && (isOnline || context.editingWidget)) && <div className="cp-toggle">
                                <div className={`cp-toggle-btn ${instore && 'selected'}`} onClick={() => !context.isEditable && setInstore(true)}>
                                    <Editable
                                        id="marsello-claim-toggle-button-instore"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.claim?.toggleButtonInstore}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, claim: { ...s.claim, toggleButtonOnline: c } } }}
                                    />
                                </div>
                                <div className={`cp-toggle-btn ${!instore && 'selected'}`} onClick={() => !context.isEditable && setInstore(false)}>
                                    <Editable
                                        id="marsello-claim-toggle-button-online"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.claim?.toggleButtonOnline}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, claim: { ...s.claim, toggleButtonOnline: c } } }}
                                    />
                                </div>
                            </div>}
                        </hgroup>
                        {!isCustomReward ? (
                            <div className="cp-popup-content">
                                {!instore ?
                                    <React.Fragment>
                                        <div className="cp-field" onClick={handleCopyToClipboard}>
                                            <strong id="marsello-discount-code">{copied ? "Added to clipboard" : reward.redeemCode}</strong>
                                            <Copy />
                                        </div>
                                        {/* <div className="cp-btn">
                                        <Editable
                                            id="marsello-claim-online-button"
                                            display="block"
                                            initialContent={context.settings?.content?.wording?.claim?.onlineButton}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, claim: { ...s.claim, onlineButton: c } } }}
                                        />
                                    </div> */}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {(context.isEditable || context.settings?.content?.wording?.card?.instructions !== "") &&
                                            <div className="cp-card-header cp-card-header-border">
                                                <p>
                                                    <Editable
                                                        id="marsello-claim-popup-claim-online-instrucitons"
                                                        display="block"
                                                        initialContent={context.settings?.content?.wording?.claim?.onlineinstructions}
                                                        isEditable={context.isEditable}
                                                        mergeTags={{ ...context.mergeTags?.default }}
                                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, claim: { ...s.claim, onlineinstructions: c } } }}
                                                    />
                                                </p>
                                            </div>
                                        }
                                        <div className="my-4">
                                            <div className="cp-qr-code">
                                                {context.customer && <QRCode value={context.customer.email} size={250} />}
                                            </div>
                                        </div>
                                        {context.customer ?
                                            <div className="mt-4">
                                                <h2>{context.customer.phone}</h2>
                                                <p><strong>{context.customer.firstName} {context.customer.lastName} &middot;</strong> {context.customer.email}</p>
                                            </div>
                                            :
                                            <div className="mt-4">
                                                <div className="cp-placeholder-heading"></div>
                                                <div className="cp-placeholder-text"></div>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            </div>) : (
                            <div className="cp-popup-content">
                                <div className="cp-card-header cp-card-header-border">
                                    <p>
                                        {reward.customRewardRedeemedFulfillmentMessage}
                                    </p>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </div >
            </div >
        </React.Fragment >
    )
}

export default ClaimPopup;
