import React, { useContext } from "react";
import { Heart, X } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { BrandingContext } from "../../../../contexts/BrandingContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';

const Launcher = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(BrandingContext);
    const customerContext = useContext(CustomerContext);
    let settings = customerContext?.settings ?? context.settings;
    let container = document.body;
    let mergeTags = context.mergeTags?.default ?? customerContext.mergeTags?.default;

    function closePortal() {
        navigation.setPage("Launcher");
        container.classList.remove("marsello-widget-open");
    }

    function openPortal() {
        navigation.setPage("Home");
        container.classList.add("marsello-widget-open");
    }

    let isOpen = navigation.page !== "Launcher"
    return (
        <div className="cp-launcher">
            <div className={`cp-launcher-btn ${isOpen ? "open" : ""}`} onClick={() => !customerContext.isEditable && (isOpen ? closePortal() : openPortal())} >
                {isOpen ?
                    <X className="cp-launcher-icon" height="20" width="20" />
                    :
                    <React.Fragment>
                        <React.Fragment>
                            {settings?.content?.icon?.useLauncherIcon &&
                                <Heart className="portal-launcher-icon" height="20" width="20" />
                            }
                            <span className="cp-launcher-label">
                                <Editable
                                    className="cp-block"
                                    id="cp-launcher-editable-visitor"
                                    initialContent={settings?.content?.wording?.launcher?.labelVisitor}
                                    isEditable={customerContext?.isEditable ?? false}
                                    mergeTags={{ ...mergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, launcher: { ...s.launcher, labelVisitor: c } } }}
                                />
                            </span>
                        </React.Fragment>
                    </React.Fragment>
                }
            </div>
        </div >
    );
};

export default Launcher;
