
import React, { useContext } from "react";
import { X } from "react-feather";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import androidInstructions from "../../../../images/AndroidInstructions.png";
import appleInstructions from "../../../../images/AppleInstructions.png";


function SaveToHomeScreenPopup() {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);

    const userAgent = navigator.userAgent.toLowerCase();

    const isIOS = /iphone|ipad|ipod/g.test(userAgent);

    return (
        <React.Fragment>
            <div className="cp-popup-container">
                <div className="cp-popup">
                    <div className="cp-popup-header">
                        <X className="cp-close-btn" onClick={() => { !context.isEditable && navigation.setPage("Card"); }} />
                    </div>
                    <div className="cp-popup-content">
                        {isIOS ? <img style={{ width: "100%" }} src={appleInstructions} alt="Instructions for adding to home screen" />
                            : <img style={{ width: "100%" }} src={androidInstructions} alt="Instructions for adding to home screen" />}
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default SaveToHomeScreenPopup;
