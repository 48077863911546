import React, { useContext, useState } from "react";
import { ActivityModel, CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Editable from '../components/Editable';
import Icon from '../util/Icon';
interface ActivityItemParams {
    action: ActivityModel;
    index: any;
}

const ActivityItem = ({ action, index }: ActivityItemParams) => {
    let iconName = (action.total ?? 0) > 0 ? "Purchase" : (action.points ?? 0) < 0 ? "Redemption" : "Adjustment";
    const context = useContext(CustomerContext);
    const [activityMergeTags, setActivityMergeTags] = useState({ completedDate: action.created ? new Date(action.created).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' }) : "" });

    const renderProgramSection = () => {
        let result = (
            <li className="cp-item cp-item-activity" key={index}>
                {((context.settings?.content?.icon?.useEarnIcon && iconName !== "Redemption") || (context.settings?.content?.icon?.useRedeemIcon && iconName === "Redemption")) &&
                    < figure className="cp-icon-container">
                        <Icon icon={iconName} />
                    </figure>
                }
                <div className="cp-item-content">
                    <h6>{action.description}</h6>
                    <small>
                        {iconName === "Purchase" &&
                            <Editable
                                id="marsello-activity-purchase-label"
                                display="block"
                                initialContent={context.settings?.content?.wording?.activity?.labelTemplatePurchase}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default, ...activityMergeTags }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, activity: { ...s.activity, labelTemplatePurchase: c } } }}
                            />
                        }
                        {iconName === "Redemption" &&
                            <Editable
                                id="marsello-activity-redemption-label"
                                display="block"
                                initialContent={context.settings?.content?.wording?.activity?.labelTemplateRedemption}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default, ...activityMergeTags }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, activity: { ...s.activity, labelTemplateRedemption: c } } }}
                            />
                        }
                        {iconName === "Adjustment" &&
                            <Editable
                                id="marsello-activity-adjustment-label"
                                display="block"
                                initialContent={context.settings?.content?.wording?.activity?.labelTemplateAdjustment}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default, ...activityMergeTags }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, activity: { ...s.activity, labelTemplateAdjustment: c } } }}
                            />
                        }
                    </small>
                </div>
                <div className="cp-item-action">
                    <small><strong>{action.points}</strong>{context?.mergeTags?.default?.pointsLabel}</small>
                </div>
            </li >
        );
        return result;
    }

    return (
        <React.Fragment>
            {renderProgramSection()}
        </React.Fragment>
    );
};

export default ActivityItem;
