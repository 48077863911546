
import React, { useContext } from "react";
import { ChevronRight } from "react-feather";
import { CustomerPortalWording, TierWithRewardsModel } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Icon from '../util/Icon';
import Editable from "./Editable";

const TierItem = ({ order, tier }: { order: number, tier: TierWithRewardsModel }) => {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);

    let tierMergeTags = {
        tierThreshold: tier.thresholdValue,
        pointsPerSpend: tier.pointsPerDollar,
        spendPerPoint: tier.moneyAmountMultiplier,
        uniqueRewardsCount: tier.linkedTierRewards?.length
    }

    const renderLabel = () => {
        let result = <React.Fragment></React.Fragment>;
        if (tier.thresholdValue === 0) {
            result = (
                <small>
                    <Editable
                        id="marsello-tier-label"
                        display="block"
                        initialContent={context.settings?.content?.wording?.home?.tierCardLabelMember}
                        isEditable={context.isEditable}
                        mergeTags={{ ...context.mergeTags?.default }}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, tierCardLabelMember: c } } }}
                    />
                </small>
            );
        } else {
            result = (
                <small>
                    <Editable
                        id="marsello-tier-threshold"
                        display="block"
                        initialContent={context.settings?.content?.wording?.home?.tierCardLabelTemplate}
                        isEditable={context.isEditable}
                        mergeTags={{ ...context.mergeTags?.default, ...tierMergeTags }}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, tierCardLabelTemplate: c } } }}
                    />
                </small>
            );
        }
        return result;
    }

    return (
        <li className="cp-item" onClick={() => !context.isEditable && navigation.setPage("VIP Program")}>
            {context.settings?.content?.icon?.useTierIcon &&
                <figure className="cp-icon-container">
                    <Icon icon="Tier" order={(context.account?.tiers?.length ?? 1) - order} />
                </figure>
            }
            <div className="cp-item-content">
                <h6 title="Edit from the VIP program settings">{tier.name}</h6>
                {renderLabel()}
            </div>
            <ChevronRight
                className="cp-icon cp-icon-chevron"
                height="18"
                width="18"
            />
        </li>
    );
};

export default TierItem;
