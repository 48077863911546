import React from 'react';
import { Outlet } from "react-router-dom";

const POSLayout = ({ children }) => {
    return (
        <React.Fragment>
            {children}
            <Outlet />
        </React.Fragment>
    );
};

export default POSLayout;