
import React, { useContext } from "react";
import { ArrowUpRight, ChevronRight } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';
import Footer from '../components/Footer';
import Prompt from '../components/Prompt';
import UserProgress from "../components/UserProgress";


function More() {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    const hasTiers = (context.account?.tiers?.length ?? 0) > 0;
    let settings = context.settings;
    const renderIcon = (isCustomerOnly: boolean, isInternal: boolean) => {
        let result = <React.Fragment></React.Fragment>;
        if (isInternal) {
            if (!context.customer && isCustomerOnly) {
                result = (
                    <span className="cp-link">
                        <Editable
                            id="marsello-more-sigin-prompt"
                            initialContent={settings?.content?.wording?.prompt?.signinLink}
                            isEditable={context.isEditable}
                            mergeTags={{ ...context.mergeTags?.default }}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, prompt: { ...s.prompt, signinLink: c } } }}
                        />
                        <ChevronRight height="18" width="18" />
                    </span>
                );
            } else {
                result = (
                    <ChevronRight height="18" width="18" />
                );
            }
        } else {
            result = (
                <ArrowUpRight height="18" width="18" />
            );
        }
        return result;
    }

    const logOut = () => {
        context.signOut();
        if (window.parent) window.parent.postMessage("logout", "*");
    }

    const openLink = (link: string | undefined | null) => {
        if (link && link != '') {
            window.open(link, "_blank");
        }
    }


    return (
        <React.Fragment>
            <div className="cp-content">
                {context.customer != null &&
                    <UserProgress />
                }
                {!!context.customer ?
                    <React.Fragment >
                        {(context.isEditable || settings?.content?.wording?.more?.listHeadingCustomer !== "") &&
                            <hgroup className="cp-basic-list-hgroup" >
                                <h6>
                                    <Editable
                                        id={"marsello-more-list-heading-customer"}
                                        display="block"
                                        initialContent={settings?.content?.wording?.more?.listHeadingCustomer}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, listHeadingCustomer: c } } }}
                                    />
                                </h6>
                            </hgroup>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {(context.isEditable || settings?.content?.wording?.more?.listHeadingVisitor !== "") &&
                            <div className="cp-basic-list-hgroup">
                                <h6>
                                    <Editable
                                        id={"marsello-more-list-heading-visitor"}
                                        display="block"
                                        initialContent={settings?.content?.wording?.more?.listHeadingVisitor}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, listHeadingVisitor: c } } }}
                                    />
                                </h6>
                            </div>
                        }
                    </React.Fragment>
                }
                <ul className="cp-basic-list">
                    <li className={`cp-item cp-item-more ${!context.customer && 'disabled'}`} onClick={() => { !context.isEditable && (context.customer ? navigation.setPage("Profile") : navigation.setPage("Signin")); }}>
                        <div className="cp-item-content">
                            {(context.isEditable || settings?.content?.wording?.more?.linkLabelProfile !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-more-link-label-profile"
                                        display="block"
                                        initialContent={settings?.content?.wording?.more?.linkLabelProfile}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, linkLabelProfile: c } } }}
                                    />
                                </p>
                            }
                        </div>
                        {renderIcon(true, true)}
                    </li>
                    <li className={`cp-item cp-item-more ${!context.customer && 'disabled'}`} onClick={() => { !context.isEditable && (context.customer ? navigation.setPage("Preferences") : navigation.setPage("Signin")); }}>
                        <div className="cp-item-content">
                            {(context.isEditable || settings?.content?.wording?.more?.linkLabelPreferences !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-more-link-label-preferences"
                                        display="block"
                                        initialContent={settings?.content?.wording?.more?.linkLabelPreferences}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, linkLabelPreferences: c } } }}
                                    />
                                </p>
                            }
                        </div>
                        {renderIcon(true, true)}
                    </li>
                    <li className={`cp-item cp-item-more ${!context.customer && 'disabled'}`} onClick={() => { !context.isEditable && (context.customer ? navigation.setPage("Activity") : navigation.setPage("Signin")); }}>
                        <div className="cp-item-content">
                            {(context.isEditable || settings?.content?.wording?.more?.linkLabelActivity !== "") &&
                                <p>
                                    <Editable
                                        id="marsello-more-link-label-activity"
                                        display="block"
                                        initialContent={settings?.content?.wording?.more?.linkLabelActivity}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, linkLabelActivity: c } } }}
                                    />
                                </p>
                            }
                        </div>
                        {renderIcon(true, true)}
                    </li>
                    {hasTiers &&
                        <li className="cp-item cp-item-more" onClick={() => { !context.isEditable && navigation.setPage("VIP Program") }}>
                            <div className="cp-item-content">
                                {(context.isEditable || settings?.content?.wording?.more?.linkLabelTier !== "") &&
                                    <p>
                                        <Editable
                                            id="marsello-more-tier-link"
                                            display="block"
                                            initialContent={settings?.content?.wording?.more?.linkLabelTier}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, linkLabelTier: c } } }}
                                        />
                                    </p>
                                }
                            </div>
                            {renderIcon(false, true)}
                        </li>
                    }
                    {settings?.content?.card?.customerReferralEnabled &&
                        <li className={`cp-item cp-item-more ${!context.customer && 'disabled'}`} onClick={() => { !context.isEditable && (context.customer ? navigation.setPage("Referral") : navigation.setPage("Signin")); }}>
                            <div className="cp-item-content">
                                {(context.isEditable || settings?.content?.wording?.more?.linkLabelReferral !== "") &&
                                    <p>
                                        <Editable
                                            id={"marsello-more-referral-link-label"}
                                            display="block"
                                            initialContent={settings?.content?.wording?.more?.linkLabelReferral}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, linkLabelReferral: c } } }}
                                        />
                                    </p>
                                }
                            </div>
                            {renderIcon(true, true)}
                        </li>}
                    {settings?.content?.link?.firstLinkEnabled && settings?.content?.link?.firstLinkLabel !== '' && settings?.content?.link?.firstLinkURL !== '' &&
                        <li className="cp-item cp-item-more" title="Edit custom links from Content settings page" onClick={() => { openLink(settings?.content?.link?.firstLinkURL) }}>
                            <div className="cp-item-content">
                                <p>{settings?.content?.link?.firstLinkLabel}</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                    {settings?.content?.link?.secondLinkEnabled && settings?.content?.link?.secondLinkLabel !== '' && settings?.content?.link?.secondLinkURL &&
                        <li className="cp-item cp-item-more">
                            <div className="cp-item-content" title="Edit custom links from Content settings page" onClick={() => { openLink(settings?.content?.link?.secondLinkURL) }}>
                                <p>{settings?.content?.link?.secondLinkLabel}</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                    {settings?.content?.link?.thirdLinkEnabled && settings?.content?.link?.thirdLinkLabel !== '' && settings?.content?.link?.thirdLinkURL !== '' &&
                        <li className="cp-item cp-item-more">
                            <div className="cp-item-content" title="Edit custom links from Content settings page" onClick={() => { openLink(settings?.content?.link?.thirdLinkURL) }}>
                                <p>{settings?.content?.link?.thirdLinkLabel}</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                    {settings?.content?.link?.fourthLinkEnabled && settings?.content?.link?.fourthLinkLabel !== '' && settings?.content?.link?.fourthLinkURL !== '' &&
                        <li className="cp-item cp-item-more">
                            <div className="cp-item-content" title="Edit custom links from Content settings page" onClick={() => { openLink(settings?.content?.link?.fourthLinkURL) }}>
                                <p>{settings?.content?.link?.fourthLinkLabel}</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                    {settings?.content?.link?.fifthLinkEnabled && settings?.content?.link?.fifthLinkLabel !== '' && settings?.content?.link?.fifthLinkURL !== '' &&
                        <li className="cp-item cp-item-more">
                            <div className="cp-item-content" title="Edit custom links from Content settings page" onClick={() => { openLink(settings?.content?.link?.fifthLinkURL) }}>
                                <p>{settings?.content?.link?.fifthLinkLabel}</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                    {context.customer &&
                        <li className="cp-item cp-item-more">
                            <div className="cp-item-content" title="Log out " onClick={() => logOut()}>
                                <p>Log out</p>
                            </div>
                            {renderIcon(false, false)}
                        </li>
                    }
                </ul>
                <Footer />
                {!context.customer &&
                    <Prompt />
                }
            </div>
        </React.Fragment>
    )
}

export default More;
