import React, { CSSProperties, useContext } from "react";
import { BrandingContext } from "../../../../contexts/BrandingContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Launcher from '../components/Launcher';
interface CenterPanelProps {
    url: string
}

const Center = (props: CenterPanelProps) => {
    const navigation = useContext(NavigationContext);
    const context = useContext(BrandingContext)

    let settings = context.settings;
    const constantStyles = { height: "90%", width: "100%", outline: "none", boxShadow: "none" }
    const iFrameStyles: CSSProperties = navigation.page !== "Launcher" ? { ...constantStyles, pointerEvents: "all", opacity: "1" } : { ...constantStyles, pointerEvents: "none", opacity: "0" }
    return (
        <div className={`cp-portal cp-portal-uncoupled cp-panel-center cp-launcher-${settings?.configuration?.launcher?.design} cp-launcher-${settings?.configuration?.launcher?.position}`}>
            <React.Fragment>
                <iframe id="marsello-widget-iframe" aria-hidden="true" title="Marsello" src={`${props.url}`} style={iFrameStyles} allow="clipboard-write"></iframe>
            </React.Fragment>
            {settings?.configuration?.launcher?.visibility !== "HideOnAll" &&
                <React.Fragment>
                    <Launcher />
                </React.Fragment>
            }
        </div>
    );
};

export default Center;
