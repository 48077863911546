import { createContext, useState } from "react";
import { useParams } from "react-router";

export interface NavigationProps {
    page: string,
    setPage: (nextPage: string) => void,
}

const initialState: NavigationProps = {
    page: "Home",
    setPage: (nextPage) => { },
}

const NavigationContext = createContext(initialState);

function NavigationProvider({ children }: any) {
    const setPage = (nextPage: string) => {
        setState({ ...state, page: nextPage })
    }
    const token = useParams();
    if (token["token"]) {
        initialState.page = "Verify";
    }
    if (window?.location?.pathname?.toLocaleLowerCase() === "/redeem") {
        initialState.page = "Redeem";
    }
    const [state, setState] = useState({ ...initialState, setPage: setPage });

    return (
        <NavigationContext.Provider value={{ ...state }}>
            {children}
        </NavigationContext.Provider>
    );
}

export { NavigationContext, NavigationProvider };

