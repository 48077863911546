import React, { useContext, useState } from "react";
import { Check } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Editable from '../components/Editable';
import Footer from '../components/Footer';
import Prompt from '../components/Prompt';
import RedeemList from "../components/RedeemList";
import UserProgressBar from "../components/UserProgressBar";
import Icon from '../util/Icon';

function Tier() {

    const context = useContext(CustomerContext);
    const [selectedTierIndex, setSelectedTierIndex] = useState(context?.account?.tiers?.findIndex(t => t.name == context?.customer?.tierName) ?? -1);

    const renderPill = (tier: any, index: number) => {
        return (
            <li className={`cp-pill ${index === selectedTierIndex && 'selected'}`} onClick={() => setSelectedTierIndex(index)}>{tier.name}</li>
        );
    }

    let tierMergeTags = {
        tierThreshold: context.account?.tiers?.[selectedTierIndex]?.thresholdValue ?? 0,
        tierName: context.account?.tiers?.[selectedTierIndex]?.name ?? "",
        pointsPerSpend: context.account?.tiers?.[selectedTierIndex]?.pointsPerDollar ?? context?.account?.earnActivities?.find(a => a.id === "order")?.points,
        spendPerPoint: context.account?.tiers?.[selectedTierIndex]?.moneyAmountMultiplier ?? context?.account?.earnActivities?.find(a => a.id === "order")?.threshold,
        uniqueRewardsCount: context.account?.tiers?.[selectedTierIndex]?.linkedTierRewards?.length ?? 0
    }

    let memberTierMergeTags = {
        memberTierName: context.mergeTags?.memberTierName,
        pointsPerSpend: context?.account?.earnActivities?.find(a => a.id === "order")?.points,
        spendPerPoint: context?.account?.earnActivities?.find(a => a.id === "order")?.threshold,
    }

    return (
        <React.Fragment>
            <div className="cp-content">
                {
                    <React.Fragment>
                        <ul className="cp-pills cp-pills-no-border">
                            <li className={`cp-pill ${selectedTierIndex === -1 && 'selected'}`} onClick={() => setSelectedTierIndex(-1)}>
                                {context.mergeTags?.memberTierName ?? "Member"}
                            </li>
                            {context.account?.tiers == null || context.account?.tiers?.length == 0 ?
                                <></>
                                :
                                context.account?.tiers.map((tier: any, i: number) => {
                                    return renderPill(tier, i);
                                })
                            }
                        </ul>
                        <div className="cp-card cp-card-tier">
                            <div className="cp-card-content">
                                {context.settings?.content?.icon?.useTierIcon && <figure className="cp-icon-container">
                                    <Icon icon="Tier" order={(context.account?.tiers?.length ?? 1) - selectedTierIndex} />
                                </figure>}
                                {selectedTierIndex === -1 ?
                                    <div className="flex-grow-1">
                                        <h3>
                                            <Editable
                                                id="marsello-tier-member-label"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.tier?.memberLabel ?? "Member"}
                                                isEditable={context.isEditable}
                                                mergeTags={{
                                                    ...context.mergeTags?.default,
                                                    ...memberTierMergeTags
                                                }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, memberLabel: c } } }}
                                            />
                                        </h3>
                                        {(context.isEditable || context.settings?.content?.wording?.tier?.memberIntroduction !== "") &&
                                            <p className="mb-1">
                                                <Editable
                                                    id="marsello-tier-introduction"
                                                    display="block"
                                                    initialContent={context.settings?.content?.wording?.tier?.memberIntroduction}
                                                    isEditable={context.isEditable}
                                                    mergeTags={{ ...context.mergeTags?.default, ...memberTierMergeTags }}
                                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, memberIntroduction: c } } }}
                                                />
                                            </p>
                                        }
                                    </div >
                                    :
                                    <div className="flex-grow-1">
                                        <h3>{context.account?.tiers?.[selectedTierIndex]?.name}</h3>
                                        {(context.isEditable || context.settings?.content?.wording?.tier?.introduction !== "") &&
                                            <p>
                                                <Editable
                                                    id="marsello-tier-introduction"
                                                    display="block"
                                                    initialContent={context.settings?.content?.wording?.tier?.introduction}
                                                    isEditable={context.isEditable}
                                                    mergeTags={{
                                                        ...context.mergeTags?.default,
                                                        ...tierMergeTags
                                                    }}
                                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, introduction: c } } }}
                                                />
                                            </p>
                                        }
                                        <ul className="cp-feature-list">
                                            {(context.isEditable || context.settings?.content?.wording?.tier?.benefit1 !== "") &&
                                                <li className="cp-feature-item">
                                                    <Check className="cp-feature-check" />
                                                    <span className="cp-feature-content">
                                                        <Editable
                                                            id="marsello-tier-benefit1"
                                                            display="block"
                                                            isEditable={context.isEditable}
                                                            initialContent={context.settings?.content?.wording?.tier?.benefit1}
                                                            mergeTags={{
                                                                ...context.mergeTags?.default,
                                                                ...tierMergeTags
                                                            }}
                                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, benefit1: c } } }}
                                                        />
                                                    </span>
                                                </li>
                                            }
                                            {(context.isEditable || context.settings?.content?.wording?.tier?.benefit2 !== "") &&
                                                <li className="cp-feature-item">
                                                    <Check className="cp-feature-check" />
                                                    <span className="cp-feature-content">
                                                        <Editable
                                                            id="marsello-tier-benefit2"
                                                            display="block"
                                                            isEditable={context.isEditable}
                                                            initialContent={context.settings?.content?.wording?.tier?.benefit2}
                                                            mergeTags={{
                                                                ...context.mergeTags?.default,
                                                                ...tierMergeTags
                                                            }}
                                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, benefit2: c } } }}
                                                        />
                                                    </span>
                                                </li>
                                            }
                                            {(context.isEditable || context.settings?.content?.wording?.tier?.benefit3 !== "") &&
                                                <li className="cp-feature-item">
                                                    <Check className="cp-feature-check" />
                                                    <span className="cp-feature-content">
                                                        <Editable
                                                            id="marsello-tier-benefit3"
                                                            display="block"
                                                            isEditable={context.isEditable}
                                                            initialContent={context.settings?.content?.wording?.tier?.benefit3}
                                                            mergeTags={{
                                                                ...context.mergeTags?.default,
                                                                ...tierMergeTags
                                                            }}
                                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, benefit3: c } } }}
                                                        />
                                                    </span>
                                                </li>
                                            }
                                        </ul>
                                    </div >
                                }
                            </div >
                            {context.customer && <UserProgressBar selectedTier={selectedTierIndex === -1 ? undefined : context.account?.tiers?.[selectedTierIndex]} />}
                        </div>
                        {(context.isEditable || context.settings?.content?.wording?.tier?.listHeading !== "") && selectedTierIndex !== -1 &&
                            <hgroup className="cp-basic-list-hgroup">
                                <h6>
                                    <Editable
                                        id="marsello-tier-list-heading"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.tier?.listHeading}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, listHeading: c } } }}
                                    />
                                </h6>
                            </hgroup>
                        }
                        <RedeemList
                            tabSelected={selectedTierIndex !== -1 ? "all" : "member"}
                            tierRewards={selectedTierIndex !== -1 ? context.account?.tiers?.[selectedTierIndex]?.linkedTierRewards : undefined}
                            tierName={selectedTierIndex !== -1 ? context.account?.tiers?.[selectedTierIndex]?.name : undefined}
                        />
                    </React.Fragment>
                }
                <Footer />
                {!context.customer &&
                    <Prompt />
                }
            </div >
        </React.Fragment >
    )
}

export default Tier;
