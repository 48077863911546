
import React, { useContext, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { CustomerApi, CustomerPortalWording } from "../../../../apiclient";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';


function Preferences() {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let settings = context.settings;
    const [submitting, setSubmitting] = useState(false);
    let customerApi = new CustomerApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );

    const [data, setData] = useState({
        subscribed: context?.customer?.subscribed,
        subscribedSms: context?.customer?.subscribedSms,
        subscribedNotifications: context?.customer?.subscribedNotifications
    });

    const handleChange = (e: any) => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        setData({ ...data, [e.target.name]: value });
    }
    const submitForm = () => {
        if (!submitting && context?.customer?.id) {
            setSubmitting(true);
            customerApi.customerCustomerIdPatch(context?.customer?.id, "1", {
                subscribed: data.subscribed,
                subscribedSms: data.subscribedSms,
                subscribedNotifications: data.subscribedNotifications
            }).then(() => {
                context.updateCustomerDetails({
                    subscribed: data.subscribed,
                    subscribedSms: data.subscribedSms,
                    subscribedNotifications: data.subscribedNotifications
                });
                setSubmitting(false);
            }).catch((error: any) => { console.log(error); });
        }
    }
    return (
        <React.Fragment>
            {context.customer != null ?
                <div className="cp-content">
                    <div className="cp-content-header">
                        <span className="cp-user-pfp-initial cp-user-pfp-xl">{context?.customer?.firstName?.charAt(0).toUpperCase()}</span>
                        <h2>{context?.customer?.firstName} {context?.customer?.lastName}</h2>
                        <div className="cp-toggle">
                            <div className="cp-toggle-btn" onClick={() => !context.isEditable && navigation.setPage("Profile")}>
                                <Editable
                                    id="marsello-preferences-toggle-profile-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.toggleProfileButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, toggleProfileButton: c } } }}
                                />
                            </div>
                            <div className="cp-toggle-btn selected">
                                <Editable
                                    id="marsello-preferences-toggle-preferences-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.togglePreferencesButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, togglePreferencesButton: c } } }}
                                />
                            </div>
                        </div>
                    </div>
                    <form className="cp-form">
                        <hgroup className="cp-basic-list-hgroup">
                            <h6>
                                <Editable
                                    id="marsello-preferences-transactional-heading"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.transactionalHeading}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, transactionalHeading: c } } }}
                                />
                            </h6>
                        </hgroup>
                        <ul className="cp-basic-list">
                            <li className="cp-item">
                                <Form.Check
                                    name="subscribedNotifications"
                                    checked={data.subscribedNotifications}
                                    type="switch"
                                    onChange={handleChange}
                                />
                                <div className="cp-item-content">
                                    <Editable
                                        id="marsello-preferences-transactional-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.transactionalEmailLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, transactionalEmailLabel: c } } }}
                                    />
                                </div>
                            </li>
                        </ul>
                        <hgroup className="cp-basic-list-hgroup">
                            <h6>
                                <Editable
                                    id="marsello-preferences-promotional-heading"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.promotionalHeading}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, promotionalHeading: c } } }}
                                />
                            </h6>
                        </hgroup>
                        <ul className="cp-basic-list">
                            <li className="cp-item">
                                <Form.Check
                                    name="subscribed"
                                    checked={data.subscribed}
                                    type="switch"
                                    onChange={handleChange}
                                />
                                <div className="cp-item-content">
                                    <Editable
                                        id="marsello-preferences-promotional-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.promotionalEmailLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, promotionalEmailLabel: c } } }}
                                    />
                                </div>
                            </li>
                            <li className="cp-item">
                                <Form.Check
                                    name="subscribedSms"
                                    checked={data.subscribedSms}
                                    type="switch"
                                    onChange={handleChange}
                                />
                                <div className="cp-item-content">
                                    <Editable
                                        id="marsello-preferences-sms-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.promotionalSMSLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, promotionalSMSLabel: c } } }}
                                    />
                                </div>
                            </li>
                        </ul>
                        <div className={submitting ? "cp-btn-disabled" : "cp-btn"} onClick={() => { !context.isEditable && submitForm() }} >
                            {submitting ? <Spinner animation="border" role="status" size="sm" ><span className="visually-hidden">Loading...</span></Spinner> :
                                <Editable
                                    id="marsello-preferences-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.preferencesButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, preferencesButton: c } } }}
                                />
                            }
                        </div>
                    </form>
                </div>
                :
                <div className="m-3 text-lg text-center">
                    <Editable
                        id="marsello-page-not-visible-preferences"
                        display="block"
                        initialContent={settings?.content?.wording?.account?.pageNotAvailableLabel ?? "This page is not visible to visitors"}
                        isEditable={context.isEditable}
                        mergeTags={{ ...context.mergeTags?.default }}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, pageNotAvailableLabel: c } } }}
                    />
                </div>
            }
        </React.Fragment>
    )
}

export default Preferences;
