import { useContext } from 'react';
import { CustomerPortalWording } from '../../../../apiclient';
import { CustomerContext } from '../../../../contexts/CustomerContext';
import { NavigationContext } from '../../../../contexts/NavigationContext';
import Editable from '../components/Editable';

const Prompt = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let settings = context.settings;
    return (
        <div className="cp-prompt">
            <div className="cp-card">
                <div className="cp-card-content text-center py-4">
                    <div className="cp-btn" onClick={() => !context.isEditable && navigation.setPage("Signup")}>
                        {(context.isEditable || settings?.content?.wording?.prompt?.button !== "") &&
                            <Editable
                                id='marsello-prompt-button'
                                display="block"
                                initialContent={settings?.content?.wording?.prompt?.button}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, prompt: { ...s.prompt, button: c } } }}
                            />
                        }
                    </div>
                    <p>
                        {(context.isEditable || settings?.content?.wording?.prompt?.signinLabel !== "") &&
                            <Editable
                                id="marsello-prompt-signin-label"
                                display="inline"
                                initialContent={settings?.content?.wording?.prompt?.signinLabel}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, prompt: { ...s.prompt, signinLabel: c } } }}
                            />
                        }
                        {(context.isEditable || settings?.content?.wording?.prompt?.signinLink !== "") &&
                            <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("Signin")}>
                                <Editable
                                    id="marsello-prompt-sigin-link"
                                    display="inline"
                                    initialContent={settings?.content?.wording?.prompt?.signinLink}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, prompt: { ...s.prompt, signinLink: c } } }}
                                />
                            </span>
                        }
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Prompt;
