import React, { useContext, useEffect, useState } from "react";
import { Check } from "react-feather";
import { CustomerPortalEarn, CustomerPortalWording, EarnActivityModel } from "../../../../../apiclient";
import { CustomerContext } from "../../../../../contexts/CustomerContext";
import Icon from '../../util/Icon';
import Editable from '../Editable';
interface EarnParams {
    earnActivity: EarnActivityModel;
    earnType: string;
    onClick?: () => void;
}


const CompletableEarnItem = ({ earnActivity, earnType, onClick }: EarnParams) => {
    const context = useContext(CustomerContext);
    const [earnMergeTags, setEarnMergeTags] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setEarnMergeTags({ pointsEarned: earnActivity.points, completedDate: earnActivity.lastRewarded ? new Date(earnActivity.lastRewarded).toLocaleString(undefined, { day: 'numeric', month: 'short', year: 'numeric' }) : "" })
        setLoading(false);
    }, [earnActivity.lastRewarded, earnActivity.points]);

    type EarnKey = keyof CustomerPortalEarn;
    return (
        <React.Fragment>
            {!loading &&
                <li className={`cp-item ${!earnActivity.available && 'complete'}`} onClick={onClick}>
                    {context.settings?.content?.icon?.useEarnIcon &&
                        <figure className="cp-icon-container">
                            <Icon icon={earnType} />
                        </figure>
                    }
                    <div className="cp-item-content">
                        <h6>
                            <Editable
                                id={`marsello-earn-heading-${earnType}`}
                                display="block"
                                initialContent={context.settings?.content?.wording?.earn?.[("heading" + earnType) as EarnKey]}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => {
                                    if (s.earn?.[("heading" + earnType) as EarnKey] != null) {
                                        s.earn[("heading" + earnType) as EarnKey] = c;
                                    }
                                    return s;
                                }}
                            />
                        </h6>
                        {earnActivity.available ?
                            <small>
                                <Editable
                                    id={`marsello-earn-label-available-${earnType}`}
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.labelTemplateAvailable}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelTemplateAvailable: c } } }}
                                />
                            </small>
                            :
                            <span className="cp-text-sm">
                                <Editable
                                    id={`marsello-earn-label-complete-${earnType}`}
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.labelTemplateComplete}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelTemplateComplete: c } } }}
                                />
                            </span>
                        }
                    </div>
                    {!earnActivity.available &&
                        <Check
                            className="cp-check"
                        />
                    }
                </li>}
        </React.Fragment>
    );
};

export default CompletableEarnItem;
