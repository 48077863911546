
import React, { useContext, useState } from "react";
import { Copy, X } from "react-feather";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterShareButton,
    XIcon
} from "react-share";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';

function ReferralPopup() {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    const [copied, setCopied] = useState(false);

    const handleCopyToClipboard = () => {
        const referralLink = getReferralLink();
        navigator.clipboard.writeText(referralLink);
        setCopied(true);
        setTimeout(() => { setCopied(false) }, 3000);
    };

    // Helper class for debugging
    const getReferralLink = () => {
        return context.customer?.referralLink ?? "";
    }

    return (
        <React.Fragment>
            <div className="cp-popup-container">
                <div className="cp-popup">
                    <div className="cp-popup-header">
                        <X className="cp-close-btn" onClick={() => { !context.isEditable && navigation.setPage("Home"); }} />
                    </div>
                    <div className="cp-popup-content">
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardHeadingCustomer !== "") &&
                            <h4>
                                <Editable
                                    id="marsello-referral-popup-heading"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardHeadingCustomer}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardHeadingCustomer: c } } }}
                                />
                            </h4>
                        }
                        {(context.isEditable || context.settings?.content?.wording?.home?.referralCardIntroductionCustomer !== "") &&
                            <p>
                                <Editable
                                    id="marsello-referral-popup-introduction"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.home?.referralCardIntroductionCustomer}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardIntroductionCustomer: c } } }}
                                />
                            </p>
                        }
                        <div className="cp-field mb-1" style={{ cursor: "pointer" }} onClick={() => { !context.isEditable && handleCopyToClipboard(); }}>
                            <strong>
                                {copied ? "Added to clipboard" : getReferralLink()}
                            </strong>
                            <Copy />
                        </div>
                        <div className="align-items-center d-flex justify-content-between mb-4">
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardInstructions !== "") &&
                                <small className="cp-text-start">
                                    <Editable
                                        id="marsello-referral-popup-instructions"
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.home?.referralCardInstructions}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardInstructions: c } } }}
                                    />
                                </small>
                            }
                            {(context.isEditable || context.settings?.content?.wording?.home?.referralCardProgress !== "") &&
                                <small className="cp-text-end">
                                    <strong>
                                        <Editable
                                            id="marsello-referral-popup-progress"
                                            display="block"
                                            initialContent={context.settings?.content?.wording?.home?.referralCardProgress}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...context.mergeTags?.referralProgram }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, referralCardProgress: c } } }}
                                        />
                                    </strong>
                                </small>
                            }
                        </div>

                        {(context.customer && context.customer.referralLink) ?
                            <ul className="cp-social-list">
                                <li className="cp-social-item">
                                    <FacebookShareButton url={getReferralLink()}>
                                        <FacebookIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                    </FacebookShareButton>
                                </li>
                                <li className="cp-social-item">
                                    <TwitterShareButton url={getReferralLink()}>
                                        <XIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                    </TwitterShareButton>
                                </li>
                                <li className="cp-social-item">
                                    <EmailShareButton url={getReferralLink()}>
                                        <EmailIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                    </EmailShareButton>
                                </li>
                                <li className="cp-social-item">
                                    <RedditShareButton url={getReferralLink()}>
                                        <RedditIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                    </RedditShareButton>
                                </li>
                            </ul>
                            :
                            <ul className="cp-social-list">
                                <li className="cp-social-item">
                                    <FacebookIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                </li>
                                <li className="cp-social-item">
                                    <XIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                </li>
                                <li className="cp-social-item">
                                    <EmailIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                </li>
                                <li className="cp-social-item">
                                    <RedditIcon size={40} round={false} borderRadius={context?.settings?.brand?.corner?.component ?? undefined} />
                                </li>
                            </ul>
                        }


                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ReferralPopup;
