import React, { useContext, useState } from "react";
import { CustomerPortalWording, TierRewardModel } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Editable from "../components/Editable";
import Footer from '../components/Footer';
import Prompt from '../components/Prompt';
import RedeemList from '../components/RedeemList';
import UserProgress from "../components/UserProgress";

function Redeem() {
    const [tabState, setTabState] = useState("all");
    const [tierName, setTierName] = useState<string | undefined>(undefined);
    const [tierRewards, setTierRewards] = useState<Array<TierRewardModel> | undefined>(undefined)
    const context = useContext(CustomerContext);

    let nextTier = context.account?.tiers?.find(t => (t.thresholdValue ?? 2) > (context.customer?.tierProgressBalance ?? 1));


    const changeTab = (tabName: string) => {
        switch (tabName) {
            case "currentTier":
                setTierName(context?.customer?.tierName ?? undefined);
                setTierRewards(context?.account?.tiers?.find(t => t.name == context?.customer?.tierName)?.linkedTierRewards);
                break;
            case "nextTier":
                setTierName(nextTier?.name);
                setTierRewards(nextTier?.linkedTierRewards);
                break;
            default:
                setTierName(undefined);
                setTierRewards(undefined);
                break;
        }
        setTabState(tabName);
    }


    return (
        <React.Fragment>
            <div className="cp-content">
                {context.customer != null && (context.account?.tiers?.length ?? 0) > 0 &&
                    <UserProgress />
                }
                {!!context.customer &&
                    <ul className="cp-pills">
                        <li className={tabState === "all" ? "cp-pill selected" : "cp-pill"} onClick={() => { changeTab("all"); }}>
                            {(context.isEditable || context.settings?.content?.wording?.redeem?.pillAll !== "") &&
                                <Editable
                                    id="marsello-redeem-pill-all"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.redeem?.pillAll}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pillAll: c } } }}
                                />
                            }
                        </li>
                        <li className={tabState === "available" ? "cp-pill selected" : "cp-pill"} onClick={() => { changeTab("available"); }}>
                            {(context.isEditable || context.settings?.content?.wording?.redeem?.pillAvailable !== "") &&
                                <Editable
                                    id="marsello-redeem-pill-available"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.redeem?.pillAvailable}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pillAvailable: c } } }}
                                />
                            }
                        </li>
                        {((tierRewards?.length ?? 0) > 1) && <>
                            <li className={tabState === "currentTier" ? "cp-pill selected" : "cp-pill"} onClick={() => { changeTab("currentTier"); }}>
                                {(context.isEditable || context.settings?.content?.wording?.redeem?.pillCurrentTier !== "") &&
                                    <Editable
                                        id={"marsello-redeem-pill-current-tier"}
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.redeem?.pillCurrentTier}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pillCurrentTier: c } } }}
                                    />
                                }
                            </li>
                            <li className={tabState === "nextTier" ? "cp-pill selected" : "cp-pill"} onClick={() => { changeTab("nextTier"); }}>
                                {(context.isEditable || context.settings?.content?.wording?.redeem?.pillNextTier !== "") &&
                                    <Editable
                                        id={"marsello-redeem-pill-next-tier"}
                                        display="block"
                                        initialContent={context.settings?.content?.wording?.redeem?.pillNextTier}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pillNextTier: c } } }}
                                    />
                                }
                            </li>
                        </>}
                    </ul>
                }
                <RedeemList
                    tabSelected={tabState}
                    tierRewards={tierRewards}
                    tierName={tierName}
                />
                <Footer />
                {!context.customer &&
                    <Prompt />
                }
            </div>
        </React.Fragment>
    )
}

export default Redeem;
