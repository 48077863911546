const Footer = () => {
    return (
        <div className="cp-footer">
            <span>We reward with <a className="cp-link cp-link-inherit" href="https://www.marsello.com/?utm_campaign=Product%20Lead%20Generation&utm_source=retailer&utm_medium=loyalty-widget&utm_term=powered-by" target="_blank" rel="noreferrer">Marsello</a></span>
            <br />
            <span><a className="cp-link cp-link-inherit" href="https://www.marsello.com/Terms" target="_blank" rel="noreferrer">Terms</a> and <a className="cp-link cp-link-inherit" href="https://www.marsello.com/Privacy" target="_blank" rel="noreferrer">Privacy Policy</a></span>
        </div>
    );
};

export default Footer;
