import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";
import routes from "./routes";

const App = () => {
    const content = useRoutes(routes);

    return (
        <HelmetProvider>
            <Helmet
                titleTemplate="%s"
                defaultTitle="Customer Rewards Portal"
            />
            {content}

        </HelmetProvider>
    );
};

export default App;

