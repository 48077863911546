import React from "react";
import Panel from '../panelTemplates/Default';
import { inIframe } from '../util/Helpers';

const PortalPanel = () => {
    return (<>
        {!inIframe() ?
            <div className={`cp-portal cp-portal-instore`}>
                <React.Fragment>
                    <Panel />
                </React.Fragment>
            </div> :

            <React.Fragment>
                <Panel />
            </React.Fragment>
        }</>
    );
};

export default PortalPanel;
