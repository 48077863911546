import { createContext, useEffect, useState } from "react";
import { AccountApi, CustomerPortalMergeTag, CustomerPortalSettings, SignInPageViewModel } from "../apiclient";
import { apiConfig } from "../config";

export interface SignInProps {
    account: SignInPageViewModel | null,
    settings: CustomerPortalSettings | null,
    mergeTags: CustomerPortalMergeTag | null,
    loading: boolean,
}
const initialState: SignInProps = {
    account: null,
    settings: null,
    mergeTags: null,
    loading: true,
}

const BrandingContext = createContext(initialState);

function BrandingProvider({ children }: any) {
    //@ts-ignore
    const baseUrl = window?.marselloSettings?.baseUrl ?? apiConfig.baseUrl;
    let accountApi = new AccountApi(
        apiConfig.configuration,
        baseUrl
    );

    const [state, setState] = useState({ ...initialState });

    useEffect(() => {
        const initialize = () => {
            if (!state.account) {
                setState(state => ({ ...state, loading: true }));
                accountApi.accountSignInGet(false)
                    .then(account => {
                        setState(state => ({ ...state, account: account.data, settings: account.data.settings ?? null, loading: false, mergeTags: account.data.accountMergeTags ?? null }));
                    }).catch((error: any) => {
                        setState(state => ({ ...state, loading: false }));
                    })
            }
        }
        initialize();
    }, []);


    return (
        <BrandingContext.Provider value={{ ...state }}>
            {state.loading ?
                <div className="cp-spinner-container" style={{ width: "100%" }}>
                </div> : children
            }
        </BrandingContext.Provider>
    );
}

export { BrandingContext, BrandingProvider };

