import React from 'react';
import moment from 'moment';

interface UserBirthdateProps {
    birthdateString: string;
}

const CustomerDOBComponent: React.FC<UserBirthdateProps> = ({ birthdateString }) => {
    // Set the moment's locale to browser locale
    moment.locale(window.navigator.language);

    if (!birthdateString || !moment(birthdateString).isValid()) {
        return <>Not Available</>;
    }

    const birthdate = moment(birthdateString);
    const age = moment().diff(birthdate, 'years');

    return (
        <>
            {birthdate.format('DD MMM YYYY')} ({age} yrs old)
        </>
    );
}

export default CustomerDOBComponent;