import { useContext, useEffect, useState } from "react";
import { CustomerApi, CustomerPortalWording } from "../../../../apiclient";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Editable from "../components/Editable";
import Footer from '../components/Footer';

function EmailLogin({ isSubmitted }: { isSubmitted: boolean }) {

    const context = useContext(CustomerContext);
    const [emailAddress, setEmailAddress] = useState("");
    const [submitted, setSubmitted] = useState(isSubmitted);
    let customerApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl);

    useEffect(() => {
        setSubmitted(isSubmitted);
    }, [isSubmitted])

    const submitForm = () => {
        setSubmitted(true);
        !context.isEditable && customerApi.customerCreateOrLoginPost("1", emailAddress)
    }

    return (
        <div className="cp-content">
            <div className="cp-card">
                <div className="cp-card-header cp-card-header-border">
                    {submitted ?
                        <>
                            <h4>
                                <Editable
                                    id="marsello-email-login-submit-header"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.submitHeading}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, submitHeading: c } } }}
                                />
                            </h4>
                            <p className="mb-3">
                                <Editable
                                    id="marsello-email-login-submitMessage"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.submitMessage}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, submitMessage: c } } }}
                                />
                            </p>
                        </>
                    :
                        <>
                            <h4>
                                <Editable
                                    id="marsello-email-login-header"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.heading}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, heading: c } } }}
                                />
                            </h4>
                            <p>
                                <Editable
                                    id="marsello-email-login-subheading"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.subheading}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, subheading: c } } }}
                                />
                            </p>
                        </>
                    }
                </div>
                {!submitted &&
                    <div className="cp-card-content">
                        <div className="form-floating">
                            <input
                                placeholder="Email address"
                                type="email"
                                id="signupEmail"
                                className="cp-form-control form-control"
                                name="email"
                                required={true}
                                onChange={(e) => { setEmailAddress(e.target.value) }}
                                value={emailAddress}
                            />
                            <label htmlFor="signupEmail" style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                <Editable
                                    id="marsello-email-login-emailLabel"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.emailLabel}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, emailLabel: c } } }}
                                />
                            </label>
                            <div className={submitted ? "cp-btn-disabled mb-0" : "cp-btn mb-0"} onClick={() => { !context.isEditable && submitForm() }}>
                                <Editable
                                    id="marsello-email-login-submitButton"
                                    display="block"
                                    isEditable={context.isEditable}
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.submitButton}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, submitButton: c } } }}
                                />
                            </div>
                        </div>
                    </div>
                }
                {!submitted &&
                    <div className="cp-card-footer cp-card-footer-border">
                        <p>
                            <Editable
                                id="marsello-signin-label"
                                display="inline"
                                initialContent={context?.settings?.content?.wording?.emailSignin?.signinLabel}
                                isEditable={context.isEditable}
                                mergeTags={{ ...context.mergeTags?.default }}
                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, signin: { ...s.emailSignin, signinLabel: c } } }}
                            />
                            <span className="cp-link" onClick={() => !context.isEditable && navigation.setPage("SignIn")}>
                                <Editable
                                    id="marsello-signin-link"
                                    display="inline"
                                    initialContent={context?.settings?.content?.wording?.emailSignin?.signinLink}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, emailSignin: { ...s.emailSignin, signinLink: c } } }}
                                />
                            </span>
                        </p>
                    </div>
                }
            </div>
            <Footer />
        </div>
    );
}

export default EmailLogin;

