import React, { useContext, useEffect, useState } from "react";
import { CustomerPortalEarn, CustomerPortalWording, EarnActivityModel } from "../../../../../apiclient";
import { CustomerContext } from "../../../../../contexts/CustomerContext";
import Icon from '../../util/Icon';
import Editable from '../Editable';
interface EarnItemParams {
    earnActivity: EarnActivityModel;
    earnType: string;
    onClick?: () => void;
}

const RepeatableEarnItem = ({ earnActivity, earnType, onClick }: EarnItemParams) => {
    const [earnMergeTags, setEarnMergeTags] = useState({});
    const [loading, setLoading] = useState(true);
    const context = useContext(CustomerContext);

    type EarnKey = keyof CustomerPortalEarn;

    useEffect(() => {
        setEarnMergeTags({ pointsEarned: earnActivity.points, spendRequired: earnActivity.threshold });
        setLoading(false);
    }, [earnActivity.points, earnActivity.threshold]);


    return (
        <React.Fragment>
            {!loading && <li className="cp-item" onClick={onClick}>
                {context.settings?.content?.icon?.useEarnIcon &&
                    <figure className="cp-icon-container">
                        <Icon icon={earnType} />
                    </figure>
                }
                <div className="cp-item-content">
                    <h6>
                        <Editable
                            id={`marsello-earn-heading-${earnType}`}
                            display="block"
                            initialContent={context.settings?.content?.wording?.earn?.[("heading" + earnType) as EarnKey]}
                            isEditable={context.isEditable}
                            mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                            fieldSetter={(s: CustomerPortalWording, c: string) => {
                                if (s.earn?.[("heading" + earnType) as EarnKey] != null) {
                                    s.earn[("heading" + earnType) as EarnKey] = c;
                                }
                                return s;
                            }}
                        />{(earnActivity.type === 34 || earnActivity.type === 35) && <>{earnActivity.title}</>}
                    </h6>
                    <small>
                        <Editable
                            id={`marsello-earn-label-avaiable-${earnType}`}
                            display="block"
                            initialContent={earnType === "Purchase" ? context.settings?.content?.wording?.earn?.labelPurchase : context.settings?.content?.wording?.earn?.labelTemplateAvailable}
                            isEditable={context.isEditable}
                            mergeTags={{ ...context.mergeTags?.default, ...earnMergeTags }}
                            fieldSetter={earnType === "Purchase" ?
                                (s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelPurchase: c } } }
                                :
                                (s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, labelTemplateAvailable: c } } }
                            }
                        />
                    </small>
                </div>
            </li>}
        </React.Fragment>
    );
};

export default RepeatableEarnItem;
