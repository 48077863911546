import POSLayout from "./layouts/POSLayout";
import PortalLayout from "./layouts/PortalLayout";
import Page404 from "./pages/Account/Page404";
import CustomersProfilePage from "./pages/Customer/CustomersProfilePage/CustomersProfilePage";
import PortalPanel from "./pages/customerPortal/portal/portalTemplates/Portal";


const routes = [

    {
        path: "/",
        element: <PortalLayout />,
        children: [
            {
                path: "",
                element: <PortalPanel />,
            },
            {
                path: "/instore",
                element: <PortalPanel />,
            },
            {
                path: "/verify/:token",
                element: <PortalPanel />,
            },
            {
                path: "/redeem",
                element: <PortalPanel />,
            },
        ],
    },
    {
        path: "/pos/kseries/",
        element: <POSLayout />,
        children: [
            {
                path: "",
                element: <CustomersProfilePage />
            }
        ]
    },
    {
        path: "*",
        element: <Page404 />,
    },
];

export default routes;
