import React, { useContext } from "react";
import { HelpCircle } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';

const UserStatus = () => {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    let settings = context.settings;
    return (
        <div className="cp-user-status" onClick={() => !context.customer && !context.isEditable && navigation.setPage("Signin")}>
            {context?.customer?.firstName ?
                <span className="cp-user-pfp-initial cp-user-pfp-sm">{context.customer.firstName.charAt(0).toUpperCase()}</span>
                :
                <HelpCircle height="24" width="24" />
            }
            {context.customer ?
                <span className="cp-user-status-label">{context.customer.balance}</span>
                :
                <React.Fragment>
                    {(context.isEditable || settings?.content?.wording?.header?.userStatusLabel !== "") &&
                        <Editable
                            id="marsello-user-status-label"
                            className="cp-user-status-label"
                            display="inline"
                            initialContent={settings?.content?.wording?.header?.userStatusLabel}
                            isEditable={context.isEditable}
                            mergeTags={{}}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, header: { ...s.header, userStatusLabel: c } } }}
                        />
                    }
                </React.Fragment>
            }
        </div>
    );
};

export default UserStatus;
