import { useContext, useEffect } from 'react';
import { CustomerPortalSettings } from '../../../../apiclient';
import { CustomerContext } from '../../../../contexts/CustomerContext';
import { NavigationContext } from '../../../../contexts/NavigationContext';
import Banner from '../components/Banner';
import Header from '../components/Header';
import Launcher from '../components/Launcher';
import Menu from '../components/Menu';
import Page from '../components/Page';
import Popup from '../components/Popup';

const Default = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    const popupIsOpen = (navigation.page === "Referral" || navigation.page === "ClaimOnline" || navigation.page === "ClaimInstore");
    var changes = context?.settings?.computedStyles ? JSON.parse(context?.settings?.computedStyles) : null;
    if (changes != null && Object.keys(changes).length > 0) {
        const root = document.querySelector(':root') as HTMLElement;
        const setChanges = (vars: any[]) => Object.entries(vars).forEach(v => root?.style?.setProperty(v[0], v[1]));
        setChanges(changes);
    }
    setFont(context.settings?.brand?.font?.default);
    setFont(context.settings?.brand?.font?.heading);
    setFont(context.settings?.brand?.font?.subheading);

    useEffect(() => {
        let listener = (event: any) => {
            if (!context.account?.allowedHosts?.includes(event.origin)) {
                console.log("This website is not configured to send data to the Marsello widget");
                return;
            }
            else {
                if (event.data.page) {
                    navigation.setPage(event.data.page);
                    event.data.selectedReward && context.updateSelectedReward(event.data.selectedReward);
                }
                if (event.data.settings) {
                    context.setCustomer(event.data.customer);
                    context.setSettings(event.data.settings as CustomerPortalSettings, event.data.isEditable ?? false, event.data.isMobile);
                }
            }
        };
        window.addEventListener(
            "message",
            listener,
            false,
        );
        return () => window.removeEventListener('message', listener);
    }, [])

    function setFont(font: string | null | undefined) {
        let fontUrl = font?.split('|')[2];
        if (fontUrl == null) fontUrl = "";
        if ((fontUrl?.length ?? 0) > 0) {
            const fontWidget = document.createElement('link');
            fontWidget.rel = 'stylesheet';
            fontWidget.href = fontUrl;
            document.head.appendChild(fontWidget);
        }
    }

    return (
        context.editingWidget ?
            <div className={`cp-portal
            ${(context.settings?.configuration?.panel?.template === "CenterPanel" ? "cp-portal-uncoupled cp-panel-center" : "cp-portal-coupled cp-panel-side")}
            ${"cp-launcher-" + context.settings?.configuration?.launcher?.design}
            ${"cp-launcher-" + context.settings?.configuration?.launcher?.position}`}>
                {navigation.page !== "Launcher" && <div className="cp-panel">
                    <div className={`cp-page ${popupIsOpen ? 'cp-popup-open' : ''} `}>
                        <Popup />
                        <main className={`cp-main cp-main-${navigation.page}`}>
                            {navigation.page === "Home" || navigation.page === "Signin" || navigation.page === "Signup" || navigation.page.includes("EmailLogin") ?
                                <Banner />
                                :
                                <Header />
                            }
                            <Page />
                        </main>
                        <Menu />
                    </div>

                </div>}
                {context.editingWidget && context.settings?.configuration?.launcher?.visibility !== "HideOnAll" &&
                    <Launcher />
                }
            </div>
            :
            <div className="cp-panel">
                <div className={`cp-page ${popupIsOpen ? 'cp-popup-open' : ''}`}>
                    <Popup />
                    <main className={`cp-main cp-main-${navigation.page}`}>
                        {navigation.page === "Home" || navigation.page === "Signin" || navigation.page === "Signup" || navigation.page.includes("EmailLogin") ?
                            <Banner />
                            :
                            <Header />
                        }
                        <Page />
                    </main>
                    <Menu />
                </div>
            </div>
    );
};

export default Default;
