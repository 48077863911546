import React, { useContext } from "react";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import ClaimPopup from '../pages/ClaimPopup';
import ReferralPopup from '../pages/ReferralPopup';
import SaveToHomeScreenPopup from "../pages/SaveToHomeScreenPopup";

const Popup = () => {
    const navigation = useContext(NavigationContext);
    let result = <React.Fragment></React.Fragment>;

    switch (navigation.page) {
        case "Referral":
            result = (
                <ReferralPopup />
            );
            break;
        case "ClaimInstore":
            result = (
                <ClaimPopup isOnline={false} />
            );
            break;
        case "ClaimOnline":
            result = (
                <ClaimPopup isOnline={true} />
            );
            break;
        case "SaveToHomeScreen":
            result = (
                <SaveToHomeScreenPopup />
            );
            break;
    }
    return result;
};

export default Popup;
