import React, { useContext } from "react";
import { ArrowLeft } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';
import UserStatus from '../components/UserStatus';


const Header = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let settings = context.settings;
    let isLandingPageOrPopup = false;
    let landingPagesAndPopups = ["Home", "Card", "Earn", "Redeem", "More", "Signup", "Signin", "Referral", "SaveToHomeScreen"];
    if (landingPagesAndPopups.includes(navigation.page)) {
        isLandingPageOrPopup = true;
    }

    const renderUserStatus = () => {

        return (
            <UserStatus />
        );
    }

    const renderHeading = () => {
        let result = <React.Fragment></React.Fragment>;

        switch (navigation.page) {
            case "Activity":
                result = (
                    <span>
                        <Editable
                            id="marsello-activity-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.activity?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, activity: { ...s.activity, pageHeading: c } } }}
                        />
                    </span>
                );
                break;
            case "Card":
            case "SaveToHomeScreen":
                result = (
                    <span>
                        <Editable
                            id="marsello-card-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.card?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, card: { ...s.card, pageHeading: c } } }}
                        />
                    </span>
                );
                break;
            case "Earn":
                result = (
                    <div>
                        <Editable
                            id="marsello-earn-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.earn?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, pageHeading: c } } }}
                        />
                    </div>
                );
                break;
            case "More":
                result = (
                    <hgroup>
                        <Editable
                            id="marsello-more-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.more?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, more: { ...s.more, pageHeading: c } } }}
                        />
                    </hgroup>
                );
                break;
            case "Preferences":
                result = (
                    <section>
                        <Editable
                            id="marsello-preferences-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.account?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, pageHeading: c } } }}
                        />
                    </section>
                );
                break;
            case "Profile":
                result = (
                    <section>
                        <Editable
                            id="marsello-profile-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.account?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, pageHeading: c } } }}
                        />
                    </section>
                );
                break;
            case "Redeem":
                result = (
                    <section>
                        <Editable
                            id="marsello-redeem-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.redeem?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, redeem: { ...s.redeem, pageHeading: c } } }}
                        />
                    </section>
                );
                break;
            case "VIP Program":
                result = (
                    <div>
                        <Editable
                            id="marsello-tier-heading"
                            display="block"
                            initialContent={settings?.content?.wording?.tier?.pageHeading}
                            isEditable={context.isEditable}
                            mergeTags={context.mergeTags?.default}
                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, tier: { ...s.tier, pageHeading: c } } }}
                        />
                    </div>
                );
                break;
            default:
                result = (
                    <Editable
                        id="marsello-home-heading"
                        display="block"
                        initialContent={settings?.content?.wording?.home?.pageHeading}
                        isEditable={context.isEditable}
                        mergeTags={context.mergeTags?.default}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, pageHeading: c } } }}
                    />
                );
                break;
        }
        return result;
    }

    let result = <React.Fragment></React.Fragment>;

    if (settings?.configuration?.panel?.template === "CenterPanel" && !context.isMobile) {
        result = (
            <header className="cp-header cp-side-header">
                <div className="cp-header-nav">
                    <span className="cp-header-heading">
                        {renderHeading()}
                    </span>
                    {/* Phase 2 <X height="24" width="24" onClick={() => !context.isEditable && navigation.setPage('Launcher')} /> */}
                </div>
            </header>
        );
    } else if (isLandingPageOrPopup) {
        result = (
            <header className="cp-header">
                <div className="cp-header-nav">
                    <figure className="cp-header-logo">
                        {settings?.brand?.logo?.logoUrl ? <img src={settings.brand.logo.logoUrl}></img> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <g transform="translate(0 0)">
                                    <path id="Path_205" data-name="Path 205" d="M20.445,9.348A8,8,0,0,0,16,8V0A16,16,0,1,1,0,16H8A8,8,0,1,0,20.445,9.348Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd" />
                                    <path id="Path_206" data-name="Path 206" d="M8,0a8,8,0,0,1-.609,3.061,8,8,0,0,1-1.734,2.6,8,8,0,0,1-2.6,1.734A8,8,0,0,1,0,8v8a16,16,0,0,0,6.123-1.218,16,16,0,0,0,5.191-3.468,16,16,0,0,0,3.468-5.191A16,16,0,0,0,16,0Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd" />
                                </g>
                            </svg>
                        }
                    </figure>
                    {renderUserStatus()}
                </div>
                <h1 className="cp-header-heading">
                    {renderHeading()}
                </h1>
            </header>
        );
    } else {
        result = (
            <header className="cp-header">
                <div className="cp-header-nav">
                    <figure className="cp-header-button" onClick={() => !context.isEditable && navigation.setPage("More")}>
                        <ArrowLeft />
                    </figure>
                    <h4 className="flex-grow-1 mb-0 mx-3">
                        {renderHeading()}
                    </h4>
                    {renderUserStatus()}
                </div>
            </header >
        );
    }
    return result;
};

export default Header;
