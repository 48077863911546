import React from "react";
import { Outlet } from "react-router-dom";
import { CustomerProvider } from "../contexts/CustomerContext";
import { NavigationProvider } from "../contexts/NavigationContext";

const PortalLayout = ({ children }) => {
    return (
        <React.Fragment>
            <NavigationProvider>
                <CustomerProvider>
                    {children}
                    <Outlet />
                </CustomerProvider>
            </NavigationProvider>
        </React.Fragment>
    );
};

export default PortalLayout;
