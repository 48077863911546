import React, { useContext } from "react";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Activity from '../pages/Activity';
import Card from '../pages/Card';
import Earn from '../pages/Earn';
import EmailLogin from "../pages/EmailLogin";
import Home from '../pages/Home';
import More from '../pages/More';
import Preferences from '../pages/Preferences';
import Profile from '../pages/Profile';
import Redeem from '../pages/Redeem';
import Signin from '../pages/Signin';
import Tier from '../pages/Tier';
import Verify from "../pages/Verify";

const Page = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let result = <React.Fragment></React.Fragment>;
    switch (navigation.page) {
        case "Activity":
            result = (
                <Activity />
            );
            break;
        case "Card":
            result = (
                <Card />
            );
            break;
        case "Earn":
            result = (
                <Earn />
            );
            break;
        case "Home":
            result = (
                <Home />
            );
            break;
        case "More":
            result = (
                <More />
            );
            break;
        case "Preferences":
            result = (
                <Preferences />
            );
            break;
        case "Profile":
            result = (
                <Profile />
            );
            break;
        case "Redeem":
            result = (
                <Redeem />
            );
            break;
        case "Signin":
            result = (
                <Signin isSignup={false} />
            );
            break;
        case "Signup":
            result = (
                <Signin isSignup={true} />
            );
            break;
        case "Verify":
            result = (
                <Verify />
            );
            break;
        case "VIP Program":
            result = (
                <Tier />
            );
            break;
        case "ClaimOnline":
            result = (
                <React.Fragment />
            );
            break;
        case "SaveToHomeScreen":
            result = (
                <Card />
            );
            break;
        case "EmailLogin":
            result = (
                <EmailLogin isSubmitted={false} />
            );
            break;
        case "EmailLoginSubmitted":
            result = (
                <EmailLogin isSubmitted={true} />
            );
            break;
        default:
            switch (!!context.customer) {
                case true:
                    result = (
                        <Home />
                    );
                    break;
                default:
                    result = (
                        <Signin isSignup={true} />
                    );
                    break;
            }
            break;
    }
    return result;
};

export default Page;
