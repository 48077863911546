import { useContext } from 'react';
import { CustomerPortalWording } from '../../../../apiclient';
import { CustomerContext } from '../../../../contexts/CustomerContext';
import { NavigationContext } from '../../../../contexts/NavigationContext';
import Editable from '../components/Editable';
import Icon from '../util/Icon';

const Menu = () => {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let settings = context.settings;
    return (
        <nav className="cp-menu">
            <div className={`cp-menu-btn ${navigation.page === "Home" && 'selected'}`} onClick={() => { !context.isEditable && navigation.setPage("Home") }}>
                <figure>
                    <Icon icon="Home" />
                </figure>
                <span>
                    <Editable
                        id="marsello-menu-home"
                        display="block"
                        initialContent={settings?.content?.wording?.menu?.labelHome}
                        isEditable={context.isEditable}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, menu: { ...s.menu, labelHome: c } } }}
                    />
                </span>
            </div>
            <div className={`cp-menu-btn ${navigation.page === "Earn" && 'selected'}`} onClick={() => { !context.isEditable && navigation.setPage("Earn") }}>
                <figure>
                    <Icon icon="Earn" />
                </figure>
                <span>
                    <Editable
                        id="marsello-menu-earn"
                        display="block"
                        initialContent={settings?.content?.wording?.menu?.labelEarn}
                        isEditable={context.isEditable}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, menu: { ...s.menu, labelEarn: c } } }}
                    />
                </span>
            </div>
            {settings?.content?.useCustomerCard && 
                <div className={`cp-menu-btn ${navigation.page === "Card" && 'selected'}`} onClick={() => { !context.isEditable && navigation.setPage("Card") }}>
                <figure>
                    <Icon icon="Card" />
                </figure>
                <span>
                    <Editable
                        id="marsello-menu-card"
                        display="block"
                        initialContent={settings?.content?.wording?.menu?.labelCard}
                        isEditable={context.isEditable}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, menu: { ...s.menu, labelCard: c } } }}
                    />
                </span>
            </div>
            }
            <div className={`cp-menu-btn ${navigation.page === "Redeem" && 'selected'}`} onClick={() => { !context.isEditable && navigation.setPage("Redeem") }}>
                <figure>
                    <Icon icon="Redeem" />
                </figure>
                <span>
                    <Editable
                        id="marsello-menu-redeem"
                        display="block"
                        initialContent={settings?.content?.wording?.menu?.labelRedeem}
                        isEditable={context.isEditable}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, menu: { ...s.menu, labelRedeem: c } } }}
                    />
                </span>
            </div>
            <div className={`cp-menu-btn ${navigation.page === "More" && 'selected'}`} onClick={() => { !context.isEditable && navigation.setPage("More") }}>
                <figure>
                    <Icon icon="More" />
                </figure>
                <span>
                    <Editable
                        id="marsello-menu-more"
                        display="block"
                        initialContent={settings?.content?.wording?.menu?.labelMore}
                        isEditable={context.isEditable}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, menu: { ...s.menu, labelMore: c } } }}
                    />
                </span>
            </div>
        </nav>
    );
};

export default Menu;
