import React from 'react';
import { Edit, Facebook, Gift, Globe, Instagram, Lock, ShoppingBag, Star, UserCheck, UserPlus, Users } from "react-feather";

const Icon = ({ icon, order }: { icon: string | null | undefined, order?: number }) => {
    let result = <React.Fragment></React.Fragment>;
    switch (icon) {
        case "FreeShipping":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25.75 20.95">
                    <path
                        className="icon-fill icon-stroke"
                        d="M16.4,7.6h2.8a2.567,2.567,0,0,1,.578.033,1.2,1.2,0,0,1,.347.144,2.566,2.566,0,0,1,.432.386l4.875,4.875a2.565,2.565,0,0,1,.385.432,1.2,1.2,0,0,1,.144.347A2.57,2.57,0,0,1,26,14.4v3.4a2.994,2.994,0,0,1-.091,1.059,1.2,1.2,0,0,1-.649.649A2.994,2.994,0,0,1,24.2,19.6m-6,0H16.4m0,0V7.84a5.962,5.962,0,0,0-.262-2.53A2.4,2.4,0,0,0,15.09,4.262,5.962,5.962,0,0,0,12.56,4H5.84a5.962,5.962,0,0,0-2.53.262A2.4,2.4,0,0,0,2.262,5.31,5.962,5.962,0,0,0,2,7.84V17.2a2.4,2.4,0,0,0,2.4,2.4m12,0H11.6m0,0a3.6,3.6,0,0,1-7.2,0m7.2,0a3.6,3.6,0,1,0-7.2,0m19.8.6a3,3,0,1,1-3-3A3,3,0,0,1,24.2,20.2Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                        transform="translate(-1.125 -3.125)"
                    />
                </svg>
            );
            break;
        case "PercentOff":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23.768 23.769">
                    <path d="M9.7,9.7h.011M16.3,16.3h.011M17.4,8.6,8.6,17.4M19.491,5.3A2.236,2.236,0,0,0,20.7,6.509l1.92.8a2.236,2.236,0,0,1,1.21,2.921l-.795,1.918a2.232,2.232,0,0,0,0,1.712l.793,1.918a2.236,2.236,0,0,1-1.21,2.922l-1.918.795A2.236,2.236,0,0,0,19.49,20.7l-.8,1.92a2.236,2.236,0,0,1-2.921,1.21l-1.918-.795a2.235,2.235,0,0,0-1.711,0l-1.92.794a2.236,2.236,0,0,1-2.919-1.209l-.8-1.92A2.236,2.236,0,0,0,5.3,19.49l-1.92-.8a2.236,2.236,0,0,1-1.211-2.92l.795-1.918a2.236,2.236,0,0,0,0-1.711L2.17,10.224A2.236,2.236,0,0,1,3.381,7.3L5.3,6.507A2.236,2.236,0,0,0,6.509,5.3l.8-1.92a2.236,2.236,0,0,1,2.921-1.21l1.918.795a2.236,2.236,0,0,0,1.711,0l1.92-.792A2.236,2.236,0,0,1,18.7,3.381l.8,1.92ZM10.25,9.7a.55.55,0,1,1-.55-.55A.55.55,0,0,1,10.25,9.7Zm6.6,6.6a.55.55,0,1,1-.55-.55A.55.55,0,0,1,16.85,16.3Z" transform="translate(-1.116 -1.115)" fill="none" stroke="#16192c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75" />
                </svg>
            );
            break;
        case "Card":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 -2 20 24">
                    <path
                        className="icon-fill icon-stroke"
                        d="M7.22,12.526h5.275v5.263M3.011,12.526H3m5.285,5.263H8.275M12.505,22h-.011M22,12.526h-.011M3,17.789H4.582m11.6-5.263H18.3M3,22H8.275m4.22-20V8.316M18.4,22h1.9a2.626,2.626,0,0,0,1.112-.115,1.054,1.054,0,0,0,.461-.46,2.609,2.609,0,0,0,.115-1.109V18.421a2.609,2.609,0,0,0-.115-1.109,1.054,1.054,0,0,0-.461-.46,2.626,2.626,0,0,0-1.112-.115H18.4a2.626,2.626,0,0,0-1.112.115,1.054,1.054,0,0,0-.461.46,2.609,2.609,0,0,0-.115,1.109v1.895a2.609,2.609,0,0,0,.115,1.109,1.054,1.054,0,0,0,.461.46A2.626,2.626,0,0,0,18.4,22Zm0-13.684h1.9A2.626,2.626,0,0,0,21.413,8.2a1.054,1.054,0,0,0,.461-.46,2.61,2.61,0,0,0,.115-1.109V4.737a2.61,2.61,0,0,0-.115-1.109,1.054,1.054,0,0,0-.461-.46A2.626,2.626,0,0,0,20.3,3.053H18.4a2.626,2.626,0,0,0-1.112.115,1.054,1.054,0,0,0-.461.46,2.61,2.61,0,0,0-.115,1.109V6.632a2.61,2.61,0,0,0,.115,1.109,1.054,1.054,0,0,0,.461.46A2.626,2.626,0,0,0,18.4,8.316Zm-13.715,0h1.9A2.626,2.626,0,0,0,7.7,8.2a1.054,1.054,0,0,0,.461-.46,2.61,2.61,0,0,0,.115-1.109V4.737A2.61,2.61,0,0,0,8.16,3.627a1.054,1.054,0,0,0-.461-.46,2.626,2.626,0,0,0-1.112-.115h-1.9a2.626,2.626,0,0,0-1.112.115,1.054,1.054,0,0,0-.461.46A2.61,2.61,0,0,0,3,4.737V6.632a2.61,2.61,0,0,0,.115,1.109,1.054,1.054,0,0,0,.461.46A2.626,2.626,0,0,0,4.688,8.316Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                        transform="translate(-2.125 -1.125)"
                    />
                </svg>
            );
            break;
        case "Earn":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 0 20 24">
                    <path
                        className="icon-fill icon-stroke"
                        d="M4,23.7H7.1a4.062,4.062,0,0,1,1.194.119l3.343.836a4.366,4.366,0,0,0,2.149,0l3.582-.716a5.1,5.1,0,0,0,2.508-1.313l2.627-2.508a1.874,1.874,0,0,0,0-2.627A1.977,1.977,0,0,0,20,17.369l-2.866,2.269a2.3,2.3,0,0,1-1.433.478H14.627a1.863,1.863,0,0,0,1.91-1.791v-.358A1.9,1.9,0,0,0,15.1,16.175l-2.866-.716a5.813,5.813,0,0,0-1.433-.119,10.458,10.458,0,0,0-3.224.955L4,17.608ZM16.657,4a2.814,2.814,0,0,0-4.179-.478A3.193,3.193,0,0,0,12,7.936,43.22,43.22,0,0,0,15.344,11.4c.478.358.6.6.836.716a.761.761,0,0,0,.716,0,5.318,5.318,0,0,0,.955-.6,31.568,31.568,0,0,0,3.463-3.343,3.1,3.1,0,0,0-.478-4.418A2.7,2.7,0,0,0,16.657,4Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                        transform="translate(-3.125 -1.912)"
                    />
                </svg>
            );
            break;
        case "Home":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path
                        className="icon-fill icon-stroke"
                        d="M10,1.5l-9,6l2-1v10c0,1.1,0.9,2,2,2h2.5V12c0-1.1,0.9-2,2-2h1c1.1,0,2,0.9,2,2v6.5H15c1.1,0,2-0.9,2-2v-10l2,1L10,1.5z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                    />
                </svg>
            );
            break;
        case "More":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 0 20 24">
                    <g className="icon-fill icon-stroke" transform="translate(1.75 1.75)">
                        <g stroke-width="1.75">
                            <circle cx="10" cy="10" r="10" stroke="none" />
                            <circle cx="10" cy="10" r="10.875" fill="none" />
                        </g>
                        <g transform="translate(8.5 9)" stroke-width="1">
                            <circle cx="1.375" cy="1.375" r="1.375" stroke="none" />
                            <circle cx="1.375" cy="1.375" r="0.875" fill="none" />
                        </g>
                        <g transform="translate(13.5 9)" stroke-width="1">
                            <circle cx="1.375" cy="1.375" r="1.375" stroke="none" />
                            <circle cx="1.375" cy="1.375" r="0.875" fill="none" />
                        </g>
                        <g transform="translate(3.5 9)" stroke-width="1">
                            <circle cx="1.375" cy="1.375" r="1.375" stroke="none" />
                            <circle cx="1.375" cy="1.375" r="0.875" fill="none" />
                        </g>
                    </g>
                </svg>
            );
            break;
        case "Redeem":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="2 -2 20 24">
                    <path
                        className="icon-fill icon-stroke"
                        d="M12,6V22M12,6H8.464a1.947,1.947,0,0,1-1.389-.586,2.026,2.026,0,0,1,0-2.828A1.947,1.947,0,0,1,8.464,2C11.214,2,12,6,12,6Zm0,0h3.536a1.947,1.947,0,0,0,1.389-.586,2.026,2.026,0,0,0,0-2.828A1.947,1.947,0,0,0,15.536,2C12.786,2,12,6,12,6Zm8,5v7.8a4.968,4.968,0,0,1-.218,2.108,2,2,0,0,1-.874.874A4.968,4.968,0,0,1,16.8,22H7.2a4.968,4.968,0,0,1-2.108-.218,2,2,0,0,1-.874-.874A4.969,4.969,0,0,1,4,18.8V11M2,7.6V9.4a2.484,2.484,0,0,0,.109,1.054,1,1,0,0,0,.437.437A2.484,2.484,0,0,0,3.6,11H20.4a2.484,2.484,0,0,0,1.054-.109,1,1,0,0,0,.437-.437A2.484,2.484,0,0,0,22,9.4V7.6a2.484,2.484,0,0,0-.109-1.054,1,1,0,0,0-.437-.437A2.484,2.484,0,0,0,20.4,6H3.6a2.484,2.484,0,0,0-1.054.109,1,1,0,0,0-.437.437A2.484,2.484,0,0,0,2,7.6Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                        transform="translate(-1.125 -1.125)"
                    />
                </svg>
            );
            break;
        case "Purchase":
            result = (
                <ShoppingBag className="cp-icon" />
            );
            break;
        case "AccountCreation":
            result = (
                <UserPlus className="cp-icon" />
            );
            break;
        case "ProfileCompletion":
            result = (
                <UserCheck className="cp-icon" />
            );
            break;
        case "FriendReferral":
            result = (
                <Users className="cp-icon" />
            );
            break;
        case "ProductReview":
            result = (
                <Star className="cp-icon" />
            );
            break;
        case "SustainableAction":
            result = (
                <Globe className="cp-icon" />
            );
            break;
        case "FacebookLike":
        case "FacebookShare":
            result = (
                <Facebook className="cp-icon" />
            );
            break;
        case "TwitterFollow":
        case "TwitterShare":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                    <line className="icon-stroke" x1="14.3" y1="9.7" x2="23.2" y2="0.8" />
                    <line className="icon-stroke" x1="0.9" y1="23.1" x2="9.8" y2="14.2" />
                    <polygon className="icon-stroke" points="1,1 16.4,23 23,23 7.6,1 	" />
                </svg>
            );
            break;
        case "InstagramFollow":
            result = (
                <Instagram className="cp-icon" />
            );
            break;
        case "Adjustment":
            result = (
                <Edit className="cp-icon" />
            );
            break;
        case "Tier":
            result = (
                <svg className="cp-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <g transform="translate(-28.4)">
                        <g transform="translate(34.4 2)">
                            <path className="icon-fill icon-stroke" d="M10.1,15.869,4.877,9.346a5.292,5.292,0,0,1-.6-.835,2.5,2.5,0,0,1-.223-.635A5.292,5.292,0,0,1,4,6.847V6a6.211,6.211,0,0,1,.272-2.635A2.5,2.5,0,0,1,5.365,2.272,6.211,6.211,0,0,1,8,2H20a6.211,6.211,0,0,1,2.635.272,2.5,2.5,0,0,1,1.092,1.093A6.211,6.211,0,0,1,24,6v.847a5.3,5.3,0,0,1-.051,1.029,2.5,2.5,0,0,1-.223.635,5.289,5.289,0,0,1-.6.835L17.9,15.869M5.25,3.25,14,14.5,22.75,3.25" transform="translate(-4 -2)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.65" />
                            <g transform="translate(1.5 12)" stroke-width="1.65">
                                <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
                                <circle cx="8.5" cy="8.5" r="7.675" fill="none" />
                            </g>
                            <text className="icon-text" transform="translate(10 24)" fill="#16192c" font-size="10" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700" letter-spacing="-0.005em"><tspan x="-2.78" y="0">{order}</tspan></text>
                        </g>
                    </g>
                </svg>
            );
            break;
        case "Lock":
            result = (
                <Lock />
            );
            break;
        case "Envelope":
            result = (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
            );
            break;
        case "Custom":
            result = (
                <Gift className="cp-icon" />
            );
            break;
        default:
            result = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="2 -2 20 24">
                    <path
                        className="cp-icon icon-fill icon-stroke"
                        d="M12,6V22M12,6H8.464a1.947,1.947,0,0,1-1.389-.586,2.026,2.026,0,0,1,0-2.828A1.947,1.947,0,0,1,8.464,2C11.214,2,12,6,12,6Zm0,0h3.536a1.947,1.947,0,0,0,1.389-.586,2.026,2.026,0,0,0,0-2.828A1.947,1.947,0,0,0,15.536,2C12.786,2,12,6,12,6Zm8,5v7.8a4.968,4.968,0,0,1-.218,2.108,2,2,0,0,1-.874.874A4.968,4.968,0,0,1,16.8,22H7.2a4.968,4.968,0,0,1-2.108-.218,2,2,0,0,1-.874-.874A4.969,4.969,0,0,1,4,18.8V11M2,7.6V9.4a2.484,2.484,0,0,0,.109,1.054,1,1,0,0,0,.437.437A2.484,2.484,0,0,0,3.6,11H20.4a2.484,2.484,0,0,0,1.054-.109,1,1,0,0,0,.437-.437A2.484,2.484,0,0,0,22,9.4V7.6a2.484,2.484,0,0,0-.109-1.054,1,1,0,0,0-.437-.437A2.484,2.484,0,0,0,20.4,6H3.6a2.484,2.484,0,0,0-1.054.109,1,1,0,0,0-.437.437A2.484,2.484,0,0,0,2,7.6Z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.75"
                        transform="translate(-1.125 -1.125)"
                    />
                </svg>
            );
            break;
    }
    return result;
};

export default Icon;
