
import React, { useContext } from "react";
import { ChevronRight } from "react-feather";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Icon from "../util/Icon";
import Editable from './Editable';
import UserProgressBar from './UserProgressBar';

const UserProgress = () => {
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    const hasTiers = (context.account?.tiers?.length ?? 0) > 0;
    let tierMergeTags = {
        currentTier: context?.customer?.tierName ?? context?.mergeTags?.memberTierName ?? "Member",
        tierThreshold: context.account?.tiers?.find(t => t.name == context.customer?.tierName)?.thresholdValue ?? 0,
        pointsPerSpend: context.account?.tiers?.find(t => t.name == context.customer?.tierName)?.pointsPerDollar ?? context?.account?.earnActivities?.find(a => a.id === "order")?.points,
        spendPerPoint: context.account?.tiers?.find(t => t.name == context.customer?.tierName)?.moneyAmountMultiplier ?? context?.account?.earnActivities?.find(a => a.id === "order")?.threshold,
        uniqueRewardsCount: context.account?.tiers?.find(t => t.name == context.customer?.tierName)?.linkedTierRewards?.length ?? 0
    }


    return (
        <div className="cp-card">
            {
                <React.Fragment>
                    {navigation.page === 'More' ?
                        <div className="cp-card-header cp-card-header-link cp-card-header-border" onClick={() => !context.isEditable && navigation.setPage("Profile")}>
                            <span className="cp-user-pfp-initial cp-user-pfp-lg">{context.customer?.firstName?.charAt(0)?.toUpperCase()}</span>
                            <div className="cp-card-hgroup">
                                <h4>{context.customer?.firstName}</h4>
                                <small>{context.customer?.email}</small><br />
                                {(context.isEditable || context.settings?.content?.wording?.userProgress?.tierStatus !== "") &&
                                    <small>
                                        <strong>
                                            <Editable
                                                id="marsello-user-progress-tier"
                                                display="block"
                                                initialContent={context.settings?.content?.wording?.userProgress?.tierStatus}
                                                isEditable={context.isEditable}
                                                mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...tierMergeTags }}
                                                fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, userProgress: { ...s.userProgress, tierStatus: c } } }}
                                            />
                                        </strong>
                                    </small>
                                }
                            </div>
                            <ChevronRight height="20" width="20" />
                        </div>
                        :
                        <div className={`cp-card-header cp-card-header-link ${navigation.page === 'Home' ? 'cp-card-header-border' : 'pb-2'}`} onClick={() => !context.isEditable && hasTiers && navigation.setPage("VIP Program")}>
                            {context.settings?.content?.icon?.useTierIcon &&
                                <figure className="cp-icon-container mb-0">
                                    <Icon icon="Tier" order={(context?.account?.tiers?.length ?? 1) - (context.account?.tiers?.findIndex(t => t.name == context.customer?.tierName) ?? -1)} />
                                </figure>
                            }
                            <div className="cp-card-hgroup">
                                <h6>{context.customer?.tierName ?? context.mergeTags?.memberTierName}</h6>
                                {(context.isEditable || context.settings?.content?.wording?.userProgress?.earningRate !== "") &&
                                    <p>
                                        <Editable
                                            id="marsello-user-progress-earn-rate"
                                            display="block"
                                            initialContent={context.settings?.content?.wording?.userProgress?.earningRate}
                                            isEditable={context.isEditable}
                                            mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer, ...tierMergeTags }}
                                            fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, userProgress: { ...s.userProgress, earningRate: c } } }}
                                        />
                                    </p>
                                }
                            </div>
                            <ChevronRight height="20" width="20" />
                        </div>
                    }
                    <UserProgressBar selectedTier={undefined} />
                </React.Fragment>
            }
        </div>
    );
};

export default UserProgress;
