import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CustomerApi } from '../../../../apiclient';
import { apiConfig } from '../../../../config';
import { CustomerContext } from '../../../../contexts/CustomerContext';
import { NavigationContext } from '../../../../contexts/NavigationContext';
import Footer from '../components/Footer';

function Verify() {
    let customerApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl)
    const [data, setData] = useState({ message: "", verified: true });
    let { token } = useParams();
    const navigate = useNavigate();
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);

    const logOut = () => {
        context.signOut();
    }

    useEffect(() => {
        const fetchData = () => {
            if (token) {
                customerApi
                    .customerVerifyTokenGet(token)
                    .then((res) => {
                        //this is only used within the portal so we can use react-router
                        navigation.setPage("Home");
                        navigate("/");
                    }).catch((err) => {
                        setData({ message: "Sorry your verification failed please try logging in again", verified: false });
                    })

            } else {
                setData({ message: "We couldn't find your verification token", verified: false });
            }
        }
        fetchData();
    }, [])

    return (
        <div className="cp-content">
            <div className="cp-card">
                <div className="cp-card-header cp-card-header-border">
                    <h4>
                        Verifying ...
                    </h4>
                    {data.message && <><p>{data.message}</p><span className="cp-link" onClick={logOut}>Log out</span></>}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Verify;
