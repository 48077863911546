import React, { useContext } from "react";
import { CustomerPortalWording } from "../../../../apiclient/models";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import Editable from '../components/Editable';
import UserStatus from '../components/UserStatus';


const Banner = () => {
    const context = useContext(CustomerContext);
    let settings = context.settings;

    return (
        <header
            className={`cp-banner ${(settings?.configuration?.panel?.template === "CenterPanel" && !context.isMobile) ? 'cp-banner-side' : ''}`}
            style={{ backgroundImage: settings?.content?.image?.bannerImageUrl ? `url(${settings?.content?.image?.bannerImageUrl})` : `linear-gradient(130deg, rgba(var(--cp-color-bannerGradientStart), 1) 0%, rgba(var(--cp-color-bannerGradientEnd), 1) 100%)` }}
        >
            <div className="cp-banner-header">
                <figure className="cp-banner-logo">
                    {(settings?.brand?.logo?.useSupplementalLogoSettings && settings?.brand?.logo?.homePageLogoUrl) ? <img src={settings.brand.logo.homePageLogoUrl} alt="logo" />
                        :
                        settings?.brand?.logo?.logoUrl ? <img src={settings.brand.logo.logoUrl} alt="logo" />
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <g transform="translate(0 0)">
                                    <path id="Path_205" data-name="Path 205" d="M20.445,9.348A8,8,0,0,0,16,8V0A16,16,0,1,1,0,16H8A8,8,0,1,0,20.445,9.348Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd" />
                                    <path id="Path_206" data-name="Path 206" d="M8,0a8,8,0,0,1-.609,3.061,8,8,0,0,1-1.734,2.6,8,8,0,0,1-2.6,1.734A8,8,0,0,1,0,8v8a16,16,0,0,0,6.123-1.218,16,16,0,0,0,5.191-3.468,16,16,0,0,0,3.468-5.191A16,16,0,0,0,16,0Z" transform="translate(0 0)" fill="#fff" fill-rule="evenodd" />
                                </g>
                            </svg>
                    }
                </figure>
                <UserStatus />
            </div>
            {context.customer ?
                <React.Fragment>
                    <hgroup className="cp-banner-hgroup" id="cp-banner-hgroup-customer">
                        {(context.isEditable || settings?.content?.wording?.home?.bannerHeadingCustomer !== "") &&
                            <span className="cp-banner-heading">
                                <Editable
                                    className="cp-block"
                                    id="cp-banner-heading-customer-editable"
                                    initialContent={settings?.content?.wording?.home?.bannerHeadingCustomer}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, bannerHeadingCustomer: c } } }}
                                />
                            </span>
                        }
                        {(context.isEditable || settings?.content?.wording?.home?.bannerSubheading !== "") &&
                            <span className="cp-banner-subheading">
                                <Editable
                                    id={"marsello-banner-subheading"}
                                    className="cp-block"
                                    initialContent={settings?.content?.wording?.home?.bannerSubheading}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default, ...context.mergeTags?.customer }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, bannerSubheading: c } } }}
                                />
                            </span>
                        }
                    </hgroup>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="cp-banner-hgroup" id="cp-banner-hgroup-visitor">
                        {(context.isEditable || settings?.content?.wording?.home?.bannerHeadingVisitor !== "") &&
                            <span className="cp-banner-heading">
                                <Editable
                                    className="cp-block"
                                    id="cp-banner-heading-visitor-editable"
                                    initialContent={settings?.content?.wording?.home?.bannerHeadingVisitor}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, home: { ...s.home, bannerHeadingVisitor: c } } }}
                                />
                            </span>
                        }
                    </div>
                </React.Fragment>
            }
        </header>
    );
};

export default Banner;
