
import DOMPurify from 'dompurify';
import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Check, X } from "react-feather";
import { CustomerPortalWording } from '../../../../apiclient';
import { CustomerContext } from '../../../../contexts/CustomerContext';
interface EditableProps {
    id: string;
    mergeTags?: any;
    initialContent?: string | null;
    className?: string;
    display?: string;
    fieldSetter?: (s: CustomerPortalWording, c: string) => CustomerPortalWording;
    isEditable: boolean;
}
const Editable = (props: EditableProps) => {
    const replaceMergeTags = (template: string, mergeTags: any) => {
        let replaced = template.toString();
        template.match(/{{\w*}}/gm)?.forEach(match => {
            let dataTag = match?.replaceAll("{", "").replaceAll("}", "");
            if (mergeTags[dataTag] || mergeTags[dataTag] === 0) {
                replaced = replaced.replaceAll(match, mergeTags[dataTag]);
            }
            else {
                replaced = replaced.replaceAll(match, "");
            }
        });
        replaced = replaced.replaceAll(/\n/gm, "<br>"); // Make newlines show up as breaks when rendered.
        return replaced;
    }
    const [content, setContent] = useState<string>(replaceMergeTags(props.initialContent ?? "", props.mergeTags));
    const [template, setTemplate] = useState<string>(props.initialContent ?? "");
    const context = useContext(CustomerContext);

    useEffect(() => {
        setContent(replaceMergeTags(props.initialContent ?? "", props.mergeTags));
        setTemplate(props.initialContent ?? "");
    }, [props.initialContent, props.mergeTags])

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const addTag = (changeEvent: ChangeEvent<HTMLSelectElement>) => {
        let editable = document.getElementById(props.id) as HTMLTextAreaElement;
        if (editable) {
            editable.value =
                editable.value?.slice(0, editable.selectionStart) +
                changeEvent.currentTarget.value +
                editable.value?.slice(editable.selectionEnd, editable.value.length);
            editable.focus();
        }
    }

    const edit = (_: MouseEvent<HTMLElement>) => {
        if (props.isEditable) {
            setIsEditing(true);
            setTimeout(() => {
                let editable = document.getElementById(props.id);
                if (editable) {
                    editable.focus();
                }
            })

        }
    }

    const setCursor = (event: MouseEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>) => {
        let editable = event.currentTarget;
        let caretOffset = editable.selectionEnd;
        // if the click is within a merge tag, select the whole tag
        let leftSide = editable?.value?.slice(0, caretOffset) ?? "";
        let rightSide = editable?.value?.slice(caretOffset, editable?.value?.length ?? 0) ?? "";
        let openingTag = leftSide.lastIndexOf("{{");
        let closingTag = rightSide.indexOf("}}");
        let nextMergeTag = rightSide.indexOf("{{");
        // Last opening tag is not closed and there is a closing tag after
        // the current cursor position and before the next tag.
        if (openingTag > leftSide.lastIndexOf("}}") &&
            (closingTag !== -1 && (nextMergeTag === -1 || nextMergeTag > closingTag))) {
            editable.setSelectionRange(openingTag, closingTag + leftSide.length + 2);
        }
        // If there is more than 1 line of text, set the height of the textarea to be the height of the content
        event.currentTarget.style.height = "";
        event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";

    }

    const undo = (_: MouseEvent<HTMLElement>) => {
        let editable = document.getElementById(props.id) as HTMLTextAreaElement;
        if (editable) {
            editable.value = template;
            setIsEditing(false);
        }
    }

    const save = (_: MouseEvent<HTMLElement>) => {
        let editable = document.getElementById(props.id) as HTMLTextAreaElement;
        if (editable && editable.value) {
            setTemplate(editable.value);
            setContent(replaceMergeTags(editable.value, props.mergeTags));
            setIsEditing(false);
            if (props.fieldSetter) {
                context.setWording(props.fieldSetter, editable.value);
            }
        }
    }

    const renderMergeTagOption = (option: any, translation: any) => {
        let mergeTag = option.toString();
        return (
            <option value={"{{" + mergeTag + "}}"} className='dropdown-item'>
                {mergeTag}
                {/* maybe put translation in here to preview the value? */}
            </option>
        )
    }


    return (
        <span className={`editable-container ${props.display ? props.display : ''}`} >
            {isEditing ?
                <>
                    <div className="editable-actions">
                        {/* {Merge tags} */}
                        {props.mergeTags && Object.keys(props.mergeTags).length > 0 && props.isEditable &&
                            <select className='editable-select' onChange={addTag}>
                                <option disabled selected>Merge tags</option>
                                {Object.keys(props.mergeTags).map((key) => {
                                    return renderMergeTagOption(key, props.mergeTags[key]);
                                })}
                            </select>
                        }
                        {/* Button group */}
                        <Button className="editable-btn mx-1" onClick={undo} variant="secondary-outline"><X className="d-block" height="16" strokeWidth="2" width="16" /></Button>
                        <Button className="editable-btn" onClick={save} variant="secondary-outline"><Check className="d-block" height="16" strokeWidth="2" width="16" /></Button>
                    </div>
                    <textarea rows="1" style={{ width: "100%", textAlign: "inherit", resize: "none" }}
                        className={`editable editing ${props.className ? props.className : ''} ${props.display ? props.display : ''}`}
                        onFocus={setCursor}
                        onMouseUp={setCursor}
                        onKeyUp={setCursor}
                        id={`${props.id ? props.id : ''}`}
                        defaultValue={template}>
                    </textarea >

                </> :
                <span
                    className={`editable ${props.className ? props.className : ''} ${props.display ? props.display : ''}`}
                    onClick={edit}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content, { USE_PROFILES: { html: true } }) }}>
                </span>
            }

        </span >
    );
};

export default Editable;
