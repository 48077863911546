
import React, { useContext, useState } from "react";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import EarnList from '../components/EarnList';
import Editable from "../components/Editable";
import Footer from '../components/Footer';
import Prompt from '../components/Prompt';
import UserProgress from "../components/UserProgress";

function Earn() {
    const [tabSelected, setTabSelected] = useState("all");
    const context = useContext(CustomerContext);
    return (
        <React.Fragment>
            <div className="cp-content">
                {context.customer != null && (context.account?.tiers?.length ?? 0) > 0 &&
                    <UserProgress />
                }
                {context.customer != null &&
                    <ul className="cp-pills">
                        <li className={tabSelected === "all" ? "cp-pill selected" : "cp-pill"} onClick={() => { !context.isEditable && setTabSelected("all") }}>
                            {(context.isEditable || context.settings?.content?.wording?.earn?.pillAll !== "") &&
                                <Editable
                                    id="marsello-earn-pill-all"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.pillAll}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, pillAll: c } } }}
                                />
                            }
                        </li>
                        <li className={tabSelected === "available" ? "cp-pill selected" : "cp-pill"} onClick={() => { !context.isEditable && setTabSelected("available") }}>
                            {(context.isEditable || context.settings?.content?.wording?.earn?.pillAvailable !== "") &&
                                <Editable
                                    id="marsello-earn-pill-available"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.pillAvailable}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, pillAvailable: c } } }}
                                />
                            }
                        </li>
                        <li className={tabSelected === "done" ? "cp-pill selected" : "cp-pill"} onClick={() => { !context.isEditable && setTabSelected("done") }}>
                            {(context.isEditable || context.settings?.content?.wording?.earn?.pillDone !== "") &&
                                <Editable
                                    id="marsello-earn-pill-done"
                                    display="block"
                                    initialContent={context.settings?.content?.wording?.earn?.pillDone}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, earn: { ...s.earn, pillDone: c } } }}
                                />
                            }
                        </li>
                    </ul>
                }
                <EarnList
                    length={undefined}
                    tabSelected={tabSelected}
                />
                <Footer />
                {!context.customer &&
                    <Prompt />
                }
            </div>
        </React.Fragment>
    )
}

export default Earn;
