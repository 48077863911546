import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ActivityModel } from "../../../../apiclient";
import { ActivitiesApi } from "../../../../apiclient/api";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import ActivityItem from '../components/ActivityItem';

interface ActivityListParams {
    tabSelected: string;
}


const ActivityList = ({ tabSelected }: ActivityListParams) => {

    let activitiesApi = new ActivitiesApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );
    const [activities, setActivities] = useState([] as Array<ActivityModel>)
    const [cursor, setCursor] = useState<string | null | undefined>();
    const [loading, setLoading] = useState(true);
    const [nextPageLoading, setNextPageLoading] = useState(false);
    const context = useContext(CustomerContext);

    /// grossness to get dummy customer working in dashboard
    const dummyData = [{
        "id": "651a3841d077f440845e4f09",
        "description": "Share us on Facebook",
        "storeName": null,
        "storeId": null,
        "customerId": "64ffffffffffffffffffff94",
        "total": 0,
        "points": 20,
        "created": "2023-10-02T03:25:53.927Z"
    },
    {
        "id": "6514f8682b13aaa451fd0622",
        "description": "Redeemed reward",
        "storeName": null,
        "storeId": null,
        "customerId": "64ffffffffffffffffffff94",
        "total": 0,
        "points": -200,
        "created": "2023-09-28T03:52:08.897Z"
    },
    {
        "id": "6514f8682b13aaa451fd0623",
        "description": "Manual Adjustment",
        "storeName": null,
        "storeId": null,
        "customerId": "64ffffffffffffffffffff94",
        "total": 0,
        "points": 200,
        "created": "2023-09-29T03:52:08.897Z"
    },
    ];


    useEffect(() => {
        setLoading(true)

        if (context.customer?.id === "64ffffffffffffffffffff94") {
            setCursor(null);
            setActivities(dummyData);
            setLoading(false);
        }
        else {
            activitiesApi.activitiesCustomerIdGet(context.customer?.id ?? "").then((res) => {
                res.data.data && setActivities(res.data.data);
                setCursor(res.data.cursor);
                setLoading(false);
            });
        }
    }, []);

    const getNextPage = () => {
        setNextPageLoading(true);
        activitiesApi.activitiesCustomerIdGet(context.customer?.id ?? "", cursor ?? "").then((res) => {
            let newActivities = activities;
            newActivities.push(...res.data.data ?? []);
            res.data.data && setActivities(newActivities);
            setCursor(res.data.cursor);
            setNextPageLoading(false);
        });
    }


    return (
        <React.Fragment>
            {loading ?
                <div className="cp-spinner-container">
                    <Spinner animation="border" role="status" />
                </div >
                :
                <ul className="cp-basic-list">
                    {tabSelected === "all" ?
                        activities.map((action, index) => (
                            <ActivityItem
                                action={action}
                                index={index}
                            />
                        ))
                        : tabSelected === "receipts" ?
                            activities.filter(a => (a.total ?? 0) > 0).map((action, index) => (
                                <ActivityItem
                                    action={action}
                                    index={index}
                                />
                            ))
                            : activities.filter(a => (a.points ?? 0) < 0).map((action, index) => (
                                <ActivityItem
                                    action={action}
                                    index={index}
                                />
                            ))
                    }
                    {cursor && (nextPageLoading ? <div className="cp-spinner-container">
                        <Spinner animation="border" role="status" />
                    </div > : <div className="cp-spinner-container"> <div className="cp-btn" onClick={getNextPage}>Load more</div></div>)}
                </ul>
            }
        </React.Fragment>
    );
};

export default ActivityList;
