import React, { useContext, useEffect, useState } from "react";
import { CouponApi, RewardModel, TierRewardModel } from "../../../../apiclient";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import RedeemItem from '../components/RedeemItem';
interface RedeemListParams {
    length?: number;
    tabSelected: string;
    tierRewards?: Array<TierRewardModel>;
    tierName?: string;
}

const RedeemList = ({ length, tabSelected, tierRewards, tierName }: RedeemListParams) => {
    const renderItem = (reward: RewardModel) => {
        return (
            <RedeemItem
                reward={reward}
                tierName={tierName}
            />
        )
    }
    const context = useContext(CustomerContext);
    const navigation = useContext(NavigationContext);
    const [rewards, setRewards] = useState<TierRewardModel[] | RewardModel[] | undefined>(undefined);
    let couponApi = new CouponApi(apiConfig.configuration, apiConfig.baseUrl + "/api");


    useEffect(() => {
        let rew = tierRewards ?? context?.customer?.rewards?.slice() ?? context?.account?.rewards?.slice();
        if (context.customer != null && tabSelected === "all" && !tierRewards) {
            // only add these on the all tab of the main redeem page
            let lockedTierRewards = context.account?.tiers?.filter(t => t.name !== context.customer?.tierName)?.map(r => r.linkedTierRewards)?.flat();
            lockedTierRewards?.forEach(l => l && rew?.push(l));
        }
        else if (context.customer != null && tabSelected === "member") {
            // on tiers page do not show tier rewards in the member tab
            var tierRewardIds = context.account?.tiers?.map(r => r.linkedTierRewards)?.flat().map(r => r?.id);
            rew = rew?.filter(r => !tierRewardIds?.includes(r.id ?? undefined));
        }
        setRewards(rew);

        let urlParams = new URLSearchParams(window.location.search);
        let couponId = urlParams.get('couponId');
        if (couponId && context.showQueryCoupon) {
            let reward = rew?.find(r => r.id === couponId.split("+")[0]);
            if (reward) {
                // we are logged in so use the coupon from the customer object
                context.updateSelectedReward(reward);
                context.setShowQueryCoupon(false); // only show coupon on first page load
                !context.isEditable && context.customer && navigation.setPage("ClaimOnline");
            }
            else {
                // call api to get coupon for customers that are not logged in
                couponApi.apiCouponCouponIdGet(couponId).then((res) => {
                    context.updateSelectedReward(res.data);
                    context.setShowQueryCoupon(false); // only show coupon on first page load
                    !context.isEditable && navigation.setPage("ClaimOnline");
                });
            }
        }

    }, [tabSelected, tierRewards, tierName, context.customer, context.account?.rewards, context.account?.tiers])



    return (
        <React.Fragment>
            {
                <ul className="cp-basic-list">
                    {rewards == null || rewards.length === 0 ?
                        <span style={{ textAlign: "center" }} ><p></p></span>
                        :
                        (tabSelected === "available" ? rewards.filter((r: RewardModel | TierRewardModel) => (r.points ?? 0) <= (context?.customer?.balance ?? 0))
                            .slice(0, (length ? length : rewards.length)).map((reward: RewardModel | TierRewardModel) => {
                                return renderItem(reward);
                            })
                            :
                            rewards.slice(0, (length ? length : rewards.length)).map((reward: RewardModel | TierRewardModel) => {
                                return renderItem(reward);
                            }))
                    }
                </ul>
            }
        </React.Fragment>
    );
};

export default RedeemList;
