import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { CustomerPortalWording } from "../../../../apiclient";
import { CustomerApi } from "../../../../apiclient/clients/customer-api";
import { apiConfig } from "../../../../config";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { NavigationContext } from "../../../../contexts/NavigationContext";
import Editable from '../components/Editable';

function Profile() {
    const navigation = useContext(NavigationContext);
    const context = useContext(CustomerContext);
    let settings = context.settings;
    const [submitting, setSubmitting] = useState(false);
    const [showCompletedProfile, setShowCompletedProfile] = useState(false);
    let customerApi = new CustomerApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );

    const [data, setData] = useState<{
        fullName: string;
        phone: string | null | undefined;
        email: string | null | undefined;
        birthday: string | undefined;
        gender: string | null | undefined;
    }>({ fullName: "", phone: "", email: "", birthday: "", gender: "" });

    useEffect(() => {
        setData({
            fullName: (context?.customer?.firstName ?? "") + ' ' + (context?.customer?.lastName ?? ""),
            phone: context?.customer?.phone,
            email: context?.customer?.email,
            birthday: context?.customer?.birthday?.split('T')[0],
            gender: context?.customer?.gender
        });
    }, [context?.customer?.birthday, context?.customer?.email, context?.customer?.firstName, context?.customer?.gender, context?.customer?.lastName, context?.customer?.phone]);

    const submitForm = () => {
        if (!submitting && context?.customer?.id && data.fullName) {
            setSubmitting(true);
            let firstName = data.fullName.trim().replace(/\s+/g, ' ').split(' ')[0];
            let lastName = data.fullName.trim().substring(firstName.length + 1);
            customerApi.customerCustomerIdPatch(context?.customer?.id, "1", {
                firstName: firstName,
                lastName: lastName,
                phone: data.phone,
                emailAddress: data.email !== context?.customer?.email ? data.email : null,
                birthday: data.birthday,
                gender: data.gender
            }).then(() => {
                if (context.customer?.earnActivities?.find(e => e.id === 'profile-completed')?.available
                    && firstName
                    && lastName
                    && data.phone
                    && data.email
                    && data.birthday
                    && data.gender) {
                    setShowCompletedProfile(true);
                    setTimeout(() => { setShowCompletedProfile(false) }, 3000);
                }

                context.refreshCustomer();
                setSubmitting(false);
            }).catch((error: any) => { console.log(error); });
        }
    }

    const handleChange = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    return (
        <React.Fragment>
            {context.customer ?
                <div className="cp-content">
                    <div className="cp-content-header">
                        <span className="cp-user-pfp-initial cp-user-pfp-xl">{context?.customer?.firstName?.charAt(0).toUpperCase()}</span>
                        <h2>{context?.customer?.firstName} {context?.customer?.lastName}</h2>
                        <div className="cp-toggle">
                            <div className="cp-toggle-btn selected">
                                <Editable
                                    id="marsello-profile-toggle-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.toggleProfileButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, toggleProfileButton: c } } }}
                                />
                            </div>
                            <div className="cp-toggle-btn" onClick={() => !context.isEditable && navigation.setPage("Preferences")}>
                                <Editable
                                    id="marsello-profile-toggle-preferences-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.togglePreferencesButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, togglePreferencesButton: c } } }}
                                />
                            </div>
                        </div>
                    </div>
                    <form className="cp-form">
                        {showCompletedProfile && <div className="cp-complete-profile-container">
                            <label className="cp-complete-profile-label">
                                {"+" + context.customer?.earnActivities?.find(e => e.id === 'profile-completed')?.points + " " + context.mergeTags?.default?.pointsLabel}
                            </label>
                        </div>}
                        <fieldset>
                            <div className="form-floating">
                                <input className="cp-form-control form-control" style={context.isEditable ? { pointerEvents: "none" } : {}} type="text" name="fullName" value={data.fullName} minLength={1} maxLength={100} required onChange={handleChange} />
                                <label style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                    <Editable
                                        id="marsello-profile-name-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.nameLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, nameLabel: c } } }}
                                    />
                                </label>
                            </div>
                            <div className="form-floating">
                                <input className="cp-form-control form-control" style={context.isEditable ? { pointerEvents: "none" } : {}} type="text" name="phone" value={data.phone ?? ""} onChange={handleChange} />
                                <label style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                    <Editable
                                        id="marsello-profile-mobile-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.mobileLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, mobileLabel: c } } }}
                                    />
                                </label>
                            </div>
                            <div className="form-floating form-floating-disabled">
                                <input className="cp-form-control form-control" style={context.isEditable ? { pointerEvents: "none" } : {}} type="email" name="email" value={data.email ?? ""} disabled />
                                <label style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                    <Editable
                                        id="marsello-profile-email-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.emailLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, emailLabel: c } } }}
                                    />
                                </label>
                            </div>
                            <div className="form-floating">
                                <input className="cp-form-control form-control" style={context.isEditable ? { pointerEvents: "none" } : {}} type="date" min="1910-01-01" max={new Date().toISOString().split("T")[0]} name="birthday" value={data.birthday ?? ""} onChange={handleChange} />
                                <label style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                    <Editable
                                        id="marsello-pofile-dob-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.dobLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, dobLabel: c } } }}
                                    />
                                </label>
                            </div>
                            <div className="form-floating">
                                <select className="cp-form-control form-control" style={context.isEditable ? { pointerEvents: "none" } : {}} name="gender" value={data.gender ?? ""} onChange={handleChange} >
                                    <option disabled={true} value={""}>Please select</option>
                                    <option value={"male"}>Male</option>
                                    <option value={"female"}>Female</option>
                                    <option value={"other"}>Not specified</option>
                                </select>
                                <label style={context.isEditable ? { pointerEvents: "all" } : {}}>
                                    <Editable
                                        id="marsello-profile-gender-label"
                                        display="block"
                                        initialContent={settings?.content?.wording?.account?.genderLabel}
                                        isEditable={context.isEditable}
                                        mergeTags={{ ...context.mergeTags?.default }}
                                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, genderLabel: c } } }}
                                    />
                                </label>
                            </div>
                        </fieldset>
                        <div className={submitting || !data.fullName ? "cp-btn-disabled" : "cp-btn"} onClick={() => { !context.isEditable && submitForm() }}>
                            {submitting ? <Spinner animation="border" role="status" size="sm" ><span className="visually-hidden">Loading...</span></Spinner> :
                                <Editable
                                    id="marsello-profile-preferences-button"
                                    display="block"
                                    initialContent={settings?.content?.wording?.account?.profileButton}
                                    isEditable={context.isEditable}
                                    mergeTags={{ ...context.mergeTags?.default }}
                                    fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, profileButton: c } } }}
                                />
                            }
                        </div>
                    </form>
                </div>
                :
                <div className="m-3 text-lg text-center">
                    <Editable
                        id="marsello-page-not-visible-profile"
                        display="block"
                        initialContent={settings?.content?.wording?.account?.pageNotAvailableLabel ?? "This page is not visible to visitors"}
                        isEditable={context.isEditable}
                        mergeTags={{ ...context.mergeTags?.default }}
                        fieldSetter={(s: CustomerPortalWording, c: string) => { return { ...s, account: { ...s.account, pageNotAvailableLabel: c } } }}
                    />
                </div>
            }
        </React.Fragment>
    )
}

export default Profile;
